export default function filterObjectKeys<TObject extends object>(
  obj: TObject,
  fn: (key: keyof TObject) => boolean
): TObject {
  const newObj: TObject = { ...obj };
  (Object.keys(newObj) as (keyof TObject)[]).forEach((key) => {
    if (!fn(key)) {
      delete newObj[key];
    }
  });
  return newObj;
}

export const skipUndefinedCondition =
  <TObject>(obj: TObject) =>
  (key: keyof TObject) =>
    obj[key] !== undefined;
