import { useCallback, useState, useMemo } from 'react';
import { SortDirection } from '../sci-ui-components/types/sort';
import useLocalStorageValue from './useLocalStorageValue';

const defaultSortStateKey = '__default_sort__';
interface SortState<TSortKey extends string = string> {
  sortBy?: TSortKey;
  sortDirection: SortDirection;
}

export interface UseSortStateReturn<TSortKey extends string = string> {
  sortDirection: SortDirection;
  sortBy?: TSortKey;
  setSort: SetSortFn<TSortKey>;
}
export type SetSortFn<TSortKey extends string = string> = (sortBy?: TSortKey, sortDirection?: SortDirection) => void;

export default function useSortState<TSortKey extends string = string>({
  defaultSortBy,
  defaultSortDirection = 'asc',
  stateKey,
}: {
  defaultSortBy?: TSortKey;
  defaultSortDirection?: SortDirection;
  stateKey?: string;
} = {}): UseSortStateReturn<TSortKey> {
  const [lastSort, setLastSort] = useLocalStorageValue<string>(stateKey || defaultSortStateKey);
  const lastSortValues = useMemo<SortState<TSortKey>>(() => {
    return stateKey && lastSort ? JSON.parse(lastSort) : {};
  }, [lastSort, stateKey]);

  const resolvedDefaultSortBy = lastSortValues.sortBy ?? defaultSortBy;
  const resolvedDefaultSortDirection = lastSortValues.sortDirection ?? defaultSortDirection;

  const [{ sortDirection, sortBy }, setState] = useState<SortState<TSortKey>>({
    sortBy: resolvedDefaultSortBy,
    sortDirection: resolvedDefaultSortDirection,
  });

  const updateLocalStorage = useCallback(
    (newState: Partial<SortState<TSortKey>>) => {
      if (stateKey && setLastSort) {
        setLastSort(JSON.stringify({ ...lastSortValues, ...newState }));
      }
    },
    [setLastSort, lastSortValues, stateKey]
  );

  const setSort = useCallback<SetSortFn<TSortKey>>(
    (sortBy, sortDirection = 'asc') => {
      setState((state) => ({
        ...state,
        sortBy,
        sortDirection,
      }));
      updateLocalStorage({ sortBy, sortDirection });
    },
    [setState, updateLocalStorage]
  );

  return {
    sortDirection,
    sortBy,
    setSort,
  };
}
