import { motion, LayoutGroup } from 'framer-motion';
import { collectibleNames } from '../collectibles/constants';
import ConfigurableWidgetsList, {
  WidgetConfigs,
  WidgetConfig,
  WidgetInstance,
} from '../../sci-ui-components/widgets/ConfigurableWidgetsList/ConfigurableWidgetsList';
import { collectibleTypes, isSportsCardCollectibleType } from '../../sci-ui-components/types/collectibleType';
import { getDashboardConfiguration, saveDashboardConfiguration } from '../../services/userSettings';
import FavoritesWidget from '../favorites/FavoritesWidget/FavoritesWidget';
import FavoritePlayersListWidget from '../favorites/FavoritePlayersListWidget/FavoritePlayersListWidget';
import SavedSearchesListWidget from '../search/saved-searches/SavedSearchesListWidget/SavedSearchesListWidget';
import { trackEvent } from '../analytics/trackEvent';
import classes from './Dashboard.module.scss';
import useShowUserPreferencesForNonUsUsers from './useShowUserPreferencesForNonUsUsers';

const widgetsRegistry: WidgetConfigs = collectibleTypes.map((collectibleType) => {
  const widgets: WidgetConfig[] = [
    {
      id: 'favorites',
      displayName: `${collectibleNames[collectibleType].shortSingular} Favorites`,
      render: () => <FavoritesWidget collectibleType={collectibleType} />,
    },
    {
      id: 'saved-searches',
      displayName: `${collectibleNames[collectibleType].shortSingular} Saved Searches`,
      render: () => <SavedSearchesListWidget collectibleType={collectibleType} />,
      sizes: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
      },
    },
  ];
  if (isSportsCardCollectibleType(collectibleType)) {
    widgets.push({
      id: 'favorite-players',
      displayName: 'Player Favorites',
      render: () => <FavoritePlayersListWidget />,
      sizes: {
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
      },
    });
  }
  return {
    id: collectibleType,
    displayName: collectibleNames[collectibleType].singular,
    widgets,
  };
});

const defaultSetup: WidgetInstance[] = [
  { id: 'sports-card.favorites' },
  { id: 'sealed-wax-card.favorites' },
  { id: 'sports-card.favorite-players' },
  { id: 'sports-card.saved-searches' },
  { id: 'sealed-wax-card.saved-searches' },
];

export default function Dashboard({ className }: { className?: string }) {
  useShowUserPreferencesForNonUsUsers();

  return (
    <div className={className}>
      <LayoutGroup>
        <motion.div layout>
          <ConfigurableWidgetsList
            className={classes.widgetsList}
            widgets={widgetsRegistry}
            defaultSetup={defaultSetup}
            persistSetup={saveDashboardConfiguration}
            getPersistedSetup={getDashboardConfiguration}
            customizeText="Customize Dashboard"
            listId="dashboard"
            onCustomizationStarted={() =>
              trackEvent({
                eventName: 'DASHBOARD_CUSTOMIZATION_STARTED',
              })
            }
            onCustomizationSaved={() =>
              trackEvent({
                eventName: 'DASHBOARD_CUSTOMIZATION_SAVED',
              })
            }
            gap="regular"
            horizontalPaddings
          />
        </motion.div>
      </LayoutGroup>
    </div>
  );
}
