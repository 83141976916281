export enum Route {
  Dashboard = '/dashboard',
  ChartsSearch = '/charts/search',
  Charts = '/charts',
  Alerts = '/alerts',
  Collections = '/collections',
  MyCollection = '/collections/my-collection',
  MassImport = '/mass-import',
  CollectionMatches = '/collections/matches',
  StatsMovements = '/stats/movements',
  SalesHistory = '/sales-history',
  CollectionsStatsMovements = '/collections/stats/movements',
  Deals = '/deals',
  MarketPulse = '/market-pulse',
  Ratios = '/ratios',
  More = '/more',
  Health = '/health',
  Admin = '/admin',
}

export enum AdminRoute {
  Dashboard = '/admin',
  UsersManagement = '/admin/users',
  UserManagement = '/admin/users/:uid',
  JobsManagement = '/admin/jobs',
  JobDetails = '/admin/jobs/',
  PlatformSettings = '/admin/settings/platform',
  Credentials = '/admin/settings/credentials',
  Members = '/',
  AutoExcludedCompletedSales = '/admin/sales/completed/auto-excluded',
  Sci = '/admin/sci',
  SciAds = '/admin/sci/ads',
  DataManager = '/admin/data',
  Warnings = '/admin/warnings',
  BulkActions = '/admin/data/bulk-actions',
  CompletedSalesLookup = '/admin/data/completed-sales-lookup',
  ManageCards = '/admin/manage-cards',
  MasterPhotos = '/admin/master-photos',
}
