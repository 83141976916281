import { ReactNode } from 'react';
import clsx from 'clsx';
import GradeIndicator, { GradeIndicatorProps } from '../../sport-cards/GradeIndicator/GradeIndicator';
import {
  getPopulationCount,
  getSportsCardGrade,
  isRookieSportsCard,
  makeCollectibleDescription,
} from '../../utils/collectibleDescription';
import { Collectible, isCustomCollectible } from '../../types/collectible';
import RookieIndicator from '../../sport-cards/RookieIndicator/RookieIndicator';
import CollectibleText from '../CollectibleText/CollectibleText';
import PopulationCount from '../PopulationCount/PopulationCount';
import ManualIndicator from '../ManualIndicator/ManualIndicator';

import classes from './CommonCollectibleDescription.module.scss';

export type CommonCollectibleDescriptionProps = {
  className?: string;
  collectible: Collectible | null;
  statSlot?: ReactNode;
  onPopulationCountClick?: () => void;
  detailsRowClassName?: string;
  descriptionClassName?: string;
  titleClassName?: string;
  rookieIndicatorClassName?: string;
  gradeIconClassName?: string;
  gradeTextClassName?: string;
  extraContentBottom?: ReactNode;
} & Partial<Omit<GradeIndicatorProps, 'className' | 'size' | 'grade'>>;

export default function CommonCollectibleDescription({
  collectible,
  onGradeChange,
  alternateGrades,
  extraContentBottom,
  ...other
}: CommonCollectibleDescriptionProps) {
  const { title, descriptionLine1, descriptionLine2 } = makeCollectibleDescription(collectible, {
    includePrintRun: true,
  });
  const isRookie = isRookieSportsCard(collectible);
  const isManual = collectible ? isCustomCollectible(collectible) : undefined;
  const populationCount = getPopulationCount(collectible);
  const grade = getSportsCardGrade(collectible);

  return (
    <CommonCollectibleFieldsDescription
      {...other}
      title={title}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      isRookie={isRookie}
      isManual={isManual}
      populationCount={populationCount}
      grade={grade}
      onGradeChange={onGradeChange}
      alternateGrades={alternateGrades}
      extraContentBottom={extraContentBottom}
    />
  );
}

export type CommonCollectibleFieldsDescriptionProps = Omit<CommonCollectibleDescriptionProps, 'collectible'> & {
  title: string;
  descriptionLine1: string;
  descriptionLine2: string;
  isRookie?: boolean | null;
  isManual?: boolean | null;
  populationCount?: number | null;
  grade?: string | null;
  rookieIndicatorClassName?: string;
  alternateGrades?: GradeIndicatorProps['alternateGrades'];
};

export function CommonCollectibleFieldsDescription({
  className,
  title,
  descriptionLine1,
  descriptionLine2,
  isRookie = false,
  isManual = false,
  populationCount,
  grade,
  statSlot,
  alternateGrades,
  onGradeChange,
  onPopulationCountClick,
  detailsRowClassName,
  descriptionClassName,
  titleClassName,
  rookieIndicatorClassName,
  gradeIconClassName,
  gradeTextClassName,
  extraContentBottom,
}: CommonCollectibleFieldsDescriptionProps) {
  return (
    <div className={clsx(classes.details, className)}>
      <div className={classes.titleRow}>
        <CollectibleText type="title" className={clsx(classes.text, titleClassName)}>
          {title}
        </CollectibleText>
        <div className="flex items-center gap-1">
          <RookieIndicator isRookie={isRookie} className={rookieIndicatorClassName} />
          <ManualIndicator isManual={isManual ?? false} />
        </div>
      </div>
      <CollectibleText type="description" className={clsx(classes.text, descriptionClassName)}>
        {descriptionLine1}
      </CollectibleText>
      <div className={clsx('flex items-start gap-2 flex-wrap', detailsRowClassName)}>
        <div className="flex flex-col gap-2">
          <CollectibleText type="description" className={clsx(classes.text, descriptionClassName)}>
            {descriptionLine2}
          </CollectibleText>
          <div className="flex gap-2 items-center">
            <GradeIndicator
              gradeIconClassName={gradeIconClassName}
              gradeTextClassName={gradeTextClassName}
              grade={grade}
              alternateGrades={alternateGrades}
              onGradeChange={onGradeChange}
              className="self-start"
            />
            <PopulationCount count={populationCount} onClick={onPopulationCountClick} className="self-center" />
          </div>
          {extraContentBottom}
        </div>
        <div className={classes.spacer} />
        {statSlot}
      </div>
    </div>
  );
}
