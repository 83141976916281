import { useMemo } from 'react';
import { mmApiClient, MmApiInput, MmApiOutput, useAuthenticatedMMAPIQuery, MmApiQueryOptions } from '@/services/mmApiX';

export type ListAlertsInput = Exclude<MmApiInput['private']['alerts']['list'], void>;
export type ListAlertsOutput = Exclude<MmApiOutput['private']['alerts']['list'], void>;
export type AlertsSortByValue = Exclude<ListAlertsInput['sort'], void>[number]['sortBy'];

export const listAlertsQueryPrefix = 'private.alerts.list' as const;

type ListAlertsQueryKey = [
  typeof listAlertsQueryPrefix,
  ListAlertsInput['filters']['collectibleType'],
  ListAlertsInput
];

export const getListAlertsQueryKeyBase = (collectibleType: ListAlertsInput['filters']['collectibleType']) =>
  [listAlertsQueryPrefix, collectibleType] as const;

export default function usePriceAlertsPaginated(
  params: ListAlertsInput,
  options?: MmApiQueryOptions<ListAlertsOutput, ListAlertsQueryKey>
) {
  const { data: countData } = useAuthenticatedMMAPIQuery(
    [listAlertsQueryPrefix, params.filters.collectibleType, params],
    () => mmApiClient.private.alerts.list.query({ ...params, offset: 0 }),
    {
      ...options,
      enabled: options?.enabled ?? true,
    }
  );

  const { data: itemData, ...queryResult } = useAuthenticatedMMAPIQuery(
    [listAlertsQueryPrefix, params.filters.collectibleType, params],
    () => mmApiClient.private.alerts.list.query({ ...params }),
    {
      ...options,
      enabled: options?.enabled ?? true,
    }
  );

  const data = useMemo(() => {
    return {
      items: itemData?.items,
      totalCount: countData?.count,
    };
  }, [countData, itemData]);

  return { data, ...queryResult };
}
