import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';

import classes from './IconButton.module.scss';

export type IconButtonSize = 'tiny' | 'small' | 'medium' | 'large';
export type IconButtonColor = 'default' | 'primary' | 'grey' | 'lightGrey' | 'white' | 'inherit' | 'danger';

export type IconButtonProps = Partial<Omit<ButtonProps, 'size' | 'color'>> & {
  faIcon: IconDefinition;
  iconClassName?: string;
  faHighlightedIcon?: IconDefinition;
  title: string;
  highlighted?: boolean;
  size?: IconButtonSize;
  color?: IconButtonColor;
  inline?: boolean;
};

export default forwardRef<HTMLElement, IconButtonProps>(function IconButton(
  {
    faIcon,
    faHighlightedIcon,
    title,
    className,
    iconClassName,
    highlighted,
    size = 'small',
    color = 'default',
    inline = false,
    ...other
  }: IconButtonProps,
  ref
) {
  return (
    <Button
      {...other}
      ref={ref}
      className={clsx(
        classes.button,
        {
          [classes.tiny]: size === 'tiny',
          [classes.small]: size === 'small',
          [classes.medium]: size === 'medium',
          [classes.large]: size === 'large',
          [classes.inline]: inline,
        },
        className
      )}
      shape="circle"
      type="text"
      title={title}
    >
      <FontAwesomeIcon
        icon={highlighted ? faHighlightedIcon ?? faIcon : faIcon}
        className={clsx(classes.icon, iconClassName, {
          [classes.colorDefault]: color === 'default',
          [classes.colorPrimary]: color === 'primary',
          [classes.colorGrey]: color === 'grey',
          [classes.colorLightGrey]: color === 'lightGrey',
          [classes.colorWhite]: color === 'white',
          [classes.colorDanger]: color === 'danger',
          [classes.colorInherit]: color === 'inherit',
          [classes.highlighted]: highlighted,
        })}
      />
    </Button>
  );
});
