import { memo, useMemo } from 'react';
import { CollectiblesSetVariation } from '@sportscardinvestor/schemas';
import { useSetVariationsListInfinite } from './useSetVariationsList';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';
import {
  AutoCompleteControlled,
  AutoCompleteOption,
  AutoCompleteProps,
} from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type SetVariationsAutoCompleteProps = Omit<
  AutoCompleteProps<CollectiblesSetVariation>,
  'options' | 'makeOption'
> & {
  /**
   * set variations only make sense within a set
   */
  setId: number | null;
  noDebounce?: boolean;
  useAdminApi?: boolean;
  limit?: number;
};
export const SetVariationsAutoComplete = memo(function CardSetVariationsAutoComplete({
  value,
  setId,
  noDebounce,
  useAdminApi,
  limit = 50,
  ...other
}: SetVariationsAutoCompleteProps) {
  const [debouncedValue, isDebouncing] = useDebouncedValue(value, noDebounce ? 0 : undefined);
  const { items, fetchNextPageIfAvailable, hasNextPage, isLoading, isFetchingNextPage } = useSetVariationsListInfinite(
    {
      filters: {
        searchTitle: debouncedValue ?? '',
        setIds: setId ? [String(setId)] : undefined,
      },
      useAdminApi,
      limit,
    },
    {
      enabled: !!setId,
    }
  );

  const options = useMemo(() => items.map(makeOption), [items]);

  return (
    <AutoCompleteControlled<CollectiblesSetVariation>
      {...other}
      options={options}
      value={value}
      hasMore={hasNextPage}
      onEndReached={fetchNextPageIfAvailable}
      isLoading={isLoading || isDebouncing || isFetchingNextPage}
      endReachedThreshold={3}
    />
  );
});

function makeOption(data: CollectiblesSetVariation): AutoCompleteOption<CollectiblesSetVariation> {
  const {
    id,
    variation: { name },
    printRun,
  } = data;
  return {
    label: `${name} ${printRun}`,
    value: name,
    key: id.toString(),
    data,
  };
}
