import { faSort, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import IconButton from '../IconButton/IconButton';
import { SortDirection } from 'sci-ui-components/types/sort';

const sortIconsMap: Record<SortDirection, IconDefinition> = {
  asc: faCaretUp,
  desc: faCaretDown,
};

export default function SortButton<TSortKey extends string = string>({
  sortDirection,
  onSortChange,
  sortKey,
  sortDescription,
  className,
  isSorted,
  defaultSortDirection = 'desc', //defaulting to desc
}: {
  sortDirection: SortDirection | null | undefined;
  sortKey: TSortKey | undefined;
  sortDescription?: string;
  onSortChange: undefined | ((sortKey: TSortKey, sortDirection: SortDirection) => void);
  className?: string;
  isSorted: boolean;
  defaultSortDirection?: SortDirection | null | undefined;
}) {
  const handleSort = () => {
    !!sortKey &&
      onSortChange?.(
        sortKey,
        sortDirection ? (sortDirection === 'asc' ? 'desc' : 'asc') : defaultSortDirection || 'desc'
      );
  };

  return (
    <IconButton
      className={className}
      faIcon={faSort}
      faHighlightedIcon={sortDirection ? sortIconsMap[sortDirection] : faCaretUp}
      highlighted={isSorted}
      title={`Sort${sortDescription ? ` by ${sortDescription}` : ''}`}
      onClick={handleSort}
      color="inherit"
    />
  );
}
