import clsx from 'clsx';

import classes from './CateogryChip.module.scss';
import CategoryChip from './CategoryChip';

export interface CategoryChipListProps {
  categories: string[] | undefined | null;
  className?: string;
  categoryChipClassName?: string;
}

/**
 * A visual indicator of the user category of a collectible
 */
export default function CategoryChipList({
  categories = null,
  className,
  categoryChipClassName,
}: CategoryChipListProps) {
  if (!categories) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {categories.map((category) => (
        <CategoryChip key={category} category={category} className={categoryChipClassName} />
      ))}
    </div>
  );
}
