import { useMemo } from 'react';
import { Sport } from '@sportscardinvestor/schemas';
import {
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import { otherSportOption } from './constants';
import useFilteredData from '@/hooks/useFilteredData';

export type { Sport } from '@sportscardinvestor/schemas';
export type UseSportsListInput = Omit<MmApiInput['private']['sports']['list'], 'limit'> & {
  useAdminApi?: boolean;
  allowOther?: boolean;
};
export type UseSportsListOutput = MmApiOutput['private']['sports']['list'];

export const useSportsListKeyPrefix = 'private.sports.list';
export type UseSportsListQueryKey = [typeof useSportsListKeyPrefix, UseSportsListInput];
function makeUseSportsListQueryKey(input: UseSportsListInput): UseSportsListQueryKey {
  return [useSportsListKeyPrefix, input];
}

export function useSportsListPaginated(
  input: UseSportsListInput,
  options?: MmApiQueryOptions<UseSportsListOutput, UseSportsListQueryKey>
) {
  const queryKey = makeUseSportsListQueryKey(input);
  const { useAdminApi, allowOther, ...commonInput } = input;
  const { data, ...rest } = useAuthenticatedMMAPIQuery(
    queryKey,
    async () =>
      mmApiClient[useAdminApi ? 'admin' : 'private'].sports.list.query({
        ...commonInput,
        limit: 10000, // NOTE: get all
        sort: commonInput.sort ?? [
          {
            sortBy: 'sportName',
            sortDirection: 'asc',
          },
        ],
      }),
    options
  );

  const items = useMemo(
    () => (allowOther && !!data?.items ? [...data.items, otherSportOption] : data?.items),
    [data?.items, allowOther]
  );

  return {
    ...rest,
    items,
    totalCount: data?.totalCount,
  };
}

export type SportsListFilters = Exclude<UseSportsListInput['filters'], void>;

/**
 * NOTE: There are not many sports to so it is better to always fetch all of them and apply filters on client side
 */
export function useFilterSports({ allSportItems, filters }: { allSportItems: Sport[]; filters: SportsListFilters }) {
  const { sportIds, searchTitle } = filters;
  const filterByIds = useMemo<(sport: Sport) => boolean>(() => {
    if (!sportIds?.length) {
      return () => true;
    }
    const idsSet = new Set(sportIds);
    return ({ id }) => idsSet.has(String(id));
  }, [sportIds]);
  const filteredItems = useFilteredData(allSportItems, 'name', searchTitle, [filterByIds]);

  return filteredItems ?? [];
}
