import { memo, useMemo } from 'react';
import { CollectibleGrade } from '@sportscardinvestor/schemas';
import { useGradesListPaginated, useFilterGrades } from './useGradesList';
import {
  AutoCompleteControlled,
  AutoCompleteOption,
  AutoCompleteControlledProps,
} from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type GradesAutoCompleteProps = Omit<AutoCompleteControlledProps<CollectibleGrade>, 'options' | 'makeOption'> & {
  isGradedOnly?: boolean;
  useAdminApi?: boolean;
  useAbbreviation?: boolean;
};
export const GradesAutoComplete = memo(function GradesAutoComplete({
  value,
  isGradedOnly,
  useAdminApi,
  useAbbreviation,
  ...other
}: GradesAutoCompleteProps) {
  const { data: { items: allGradeItems = [] } = {}, isLoading } = useGradesListPaginated({
    useAdminApi,
  });
  const filteredGradeItems = useFilterGrades({
    allGradeItems,
    filters: {
      searchTitle: value ?? undefined,
      isGradedOnly,
    },
  });

  const options = useMemo(
    () => filteredGradeItems.map((v) => makeOption(v, useAbbreviation)),
    [filteredGradeItems, useAbbreviation]
  );

  return <AutoCompleteControlled<CollectibleGrade> {...other} options={options} value={value} isLoading={isLoading} />;
});

function makeOption(
  grade: CollectibleGrade,
  useAbbreviation: boolean | undefined
): AutoCompleteOption<CollectibleGrade> {
  const { id, name, abbreviatedName } = grade;
  const label = useAbbreviation && abbreviatedName ? abbreviatedName : name;
  return {
    label,
    value: label,
    key: id.toString(),
    data: grade,
  };
}
