import { memo, useMemo } from 'react';
import { Player } from '@sportscardinvestor/schemas';
import { usePlayersListInfinite } from './usePlayersList';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';
import {
  AutoCompleteControlled,
  AutoCompleteOption,
  AutoCompleteControlledProps,
} from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type PlayersAutoCompleteProps = Omit<AutoCompleteControlledProps<Player>, 'options' | 'makeOption'> & {
  sportIds?: number[];
  noDebounce?: boolean;
  useAdminApi?: boolean;
  limit?: number;
};
export const PlayersAutoComplete = memo(function CardPlayersAutoComplete({
  value,
  sportIds,
  noDebounce,
  useAdminApi,
  limit = 50,
  ...other
}: PlayersAutoCompleteProps) {
  const [debouncedValue, isDebouncing] = useDebouncedValue(value, noDebounce ? 0 : undefined);
  const { items, fetchNextPageIfAvailable, hasNextPage, isLoading, isFetchingNextPage } = usePlayersListInfinite({
    filters: {
      searchTitle: debouncedValue ?? '',
      sportIds: sportIds?.map((v) => String(v)),
    },
    useAdminApi,
    sort: [
      {
        sortBy: 'playerName',
        sortDirection: 'asc',
      },
    ],
    limit,
  });

  const options = useMemo(() => items.map(makeOption), [items]);

  return (
    <AutoCompleteControlled<Player>
      {...other}
      options={options}
      value={value}
      hasMore={hasNextPage}
      onEndReached={fetchNextPageIfAvailable}
      isLoading={isLoading || isDebouncing || isFetchingNextPage}
      endReachedThreshold={3}
    />
  );
});

function makeOption(set: Player): AutoCompleteOption<Player> {
  const { id, name } = set;
  return {
    label: name,
    value: name,
    key: id.toString(),
    data: set,
  };
}
