import { memo, useMemo } from 'react';
import { BoxType } from '@sportscardinvestor/schemas';
import { useBoxTypesListPaginated, useFilterBoxTypes } from './useBoxTypesList';
import {
  AutoCompleteControlled,
  AutoCompleteOption,
  AutoCompleteControlledProps,
} from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type BoxTypesAutoCompleteProps = Omit<AutoCompleteControlledProps<BoxType>, 'options' | 'makeOption'> & {
  useAdminApi?: boolean;
  allowAny?: boolean;
};
export const BoxTypesAutoComplete = memo(function BoxTypesAutoComplete({
  value,
  useAdminApi,
  allowAny,
  ...other
}: BoxTypesAutoCompleteProps) {
  const { items: allBoxTypeItems = [], isLoading } = useBoxTypesListPaginated({
    useAdminApi,
    allowAny,
  });
  const filteredBoxTypeItems = useFilterBoxTypes({
    allBoxTypeItems,
    filters: {
      searchTitle: value ?? undefined,
    },
  });

  const options = useMemo(() => filteredBoxTypeItems.map((v) => makeOption(v)), [filteredBoxTypeItems]);

  return <AutoCompleteControlled<BoxType> {...other} options={options} value={value} isLoading={isLoading} />;
});

function makeOption(boxType: BoxType): AutoCompleteOption<BoxType> {
  const { id, name } = boxType;
  return {
    label: name,
    value: name,
    key: id.toString(),
    data: boxType,
  };
}
