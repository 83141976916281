import { showUserPreferencesModal } from 'features/user/UserPreferencesModal';
import useAccessFlags from 'hooks/useAccessFlags';
import useUserSettings from 'features/settings/useUserSettings';
import { getGeolocation } from 'services/externalServices/ipapi';
import useAuth from 'features/auth/useAuth';
import { useOnValueChange } from '@/hooks/useOnValueChange';

export default function useShowUserPreferencesForNonUsUsers() {
  const isUserPreferenceVisible = useAccessFlags({ flag: 'isUserPreferenceVisible' });
  const {
    data: userSettingsData,
    updateMutation: updateUserSettings,
    isLoading: isLoadingUserSettings,
  } = useUserSettings();
  const { isLoggedIn } = useAuth();
  const seenCurrencyModal = userSettingsData?.globalSettings?.seenFeatures?.currencyModal;
  const shouldShowModal = !!isLoggedIn && !isLoadingUserSettings && !!isUserPreferenceVisible && !seenCurrencyModal;

  useOnValueChange(shouldShowModal, () => {
    if (shouldShowModal) {
      (async () => {
        const geolocation = await getGeolocation();
        if (geolocation.country_code_iso3 !== 'USA') {
          showUserPreferencesModal({});
        }
        updateUserSettings.mutate({
          globalSettings: {
            seenFeatures: {
              currencyModal: true,
            },
          },
        });
      })();
    }
  });
}
