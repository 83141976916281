import { useMemo } from 'react';
import { Collectible, CustomCollectible } from '@sportscardinvestor/schemas';
import { isSportsCardCollectible, isNonCustomCollectible } from '@sportscardinvestor/collectible-helpers';
import {
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import { AlternateGrade } from '@/sci-ui-components/sport-cards/GradeIndicator/GradeIndicator';
import {
  Collectible as CollectibleOldMMXType,
  isNonCustomSportsCardCollectible as isNonCustomSportsCardCollectibleOldMMXType,
} from '@/sci-ui-components/types/collectible';

export type UseAvailableGradesQueryInput = MmApiInput['private']['collectibles']['filters']['availableGradesForCard'];
export type UseAvailableGradesOutput = MmApiOutput['private']['collectibles']['filters']['availableGradesForCard'];
export type AvailableGradeOption = UseAvailableGradesOutput['options'][number];

export const useAvailableGradesKeyPrefix = 'private.collectibles.filters.availableGradesForCard';
export type UseAvailableGradesQueryKey = [typeof useAvailableGradesKeyPrefix, UseAvailableGradesInput];
function makeUseAvailableGradesQueryKey(input: UseAvailableGradesInput): UseAvailableGradesQueryKey {
  return [useAvailableGradesKeyPrefix, input];
}

export type UseAvailableGradesInput = UseAvailableGradesQueryInput & {
  excludeCollectibleIds?: number[];
};

/**
 * NOTE: this API does not support pagination
 */
export function useAvailableGrades(
  { excludeCollectibleIds, ...input }: UseAvailableGradesInput,
  options?: MmApiQueryOptions<UseAvailableGradesOutput, UseAvailableGradesQueryKey>
) {
  const { data, ...result } = useAuthenticatedMMAPIQuery(
    makeUseAvailableGradesQueryKey(input),
    async () => mmApiClient.private.collectibles.filters.availableGradesForCard.query(input),
    options
  );

  const items = useMemo(
    () =>
      excludeCollectibleIds?.length
        ? data?.options?.filter((v) => !excludeCollectibleIds?.some((id) => id === v.card_id))
        : data?.options,
    [excludeCollectibleIds, data?.options]
  );

  return {
    ...result,
    items,
  };
}

export function useMapToAlternateGrade(availableGrades: AvailableGradeOption[] | undefined | null) {
  return useMemo(
    () =>
      availableGrades?.map<AlternateGrade>(({ card_id, grade_name }) => ({
        collectibleId: card_id,
        gradeName: grade_name,
      })),
    [availableGrades]
  );
}

/**
 * Helper hook to make migration to new api (useAvailableGrades) easier
 */
export function useAlternateGradesForCollectible({
  collectible,
  oldCollectible,
  excludeCollectibleIds = [],
  enabled = true,
}: {
  collectible?: null | Collectible | CustomCollectible;
  oldCollectible?: null | CollectibleOldMMXType;
  excludeCollectibleIds?: number[];
  enabled?: boolean;
} & (
  | {
      collectible: null | Collectible | CustomCollectible;
    }
  | {
      oldCollectible: null | CollectibleOldMMXType;
    }
)) {
  const identifiers = collectible
    ? extractCardIdentifiersFromCollectible(collectible)
    : extractCardIdentifiersFromOldCollectible(oldCollectible ?? null);
  const collectibleId = collectible?.id ?? oldCollectible?.id;
  const { items: availableGrades, ...rest } = useAvailableGrades(
    {
      ...(identifiers ?? {
        // NOTE: this should not be used, but here to satisfy TS
        player_id: -1,
        set_id: -1,
        variation_id: -1,
        card_number: null,
        specific_qualifier: null,
      }),
      excludeCollectibleIds: collectibleId ? [...excludeCollectibleIds, collectibleId] : excludeCollectibleIds,
    },
    {
      enabled: !!identifiers && enabled,
    }
  );
  const alternateGrades = useMapToAlternateGrade(availableGrades);
  return {
    ...rest,
    alternateGrades,
  };
}

function extractCardIdentifiersFromCollectible(
  collectible: null | Collectible | CustomCollectible
): UseAvailableGradesQueryInput | null {
  if (collectible && isNonCustomCollectible(collectible) && isSportsCardCollectible(collectible)) {
    return {
      player_id: collectible.player.id,
      set_id: collectible.set.id,
      variation_id: collectible.setVariation.variation.id,
      card_number: collectible.cardNumber,
      specific_qualifier: collectible.specificQualifier,
    };
  }
  return null;
}

function extractCardIdentifiersFromOldCollectible(
  collectible: null | CollectibleOldMMXType
): UseAvailableGradesQueryInput | null {
  if (collectible && isNonCustomSportsCardCollectibleOldMMXType(collectible)) {
    return {
      player_id: collectible.playerId,
      set_id: collectible.cardSetId,
      variation_id: collectible.variationId,
      card_number: collectible.cardNumber,
      specific_qualifier: collectible.specificQualifier,
    };
  }
  return null;
}
