import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { addFavoritePlayer } from '../favorites/AddOrEditFavoritePlayerDialog/index';
import { useFavoriteIds } from '../favorites/useFavoriteIds';
import useFavoritePlayersMutations from '../favorites/useFavoritePlayersMutations';
import makeStatsMovementsUrl, { MakeStatsMovementsUrlParams } from '../stats/utils/makeStatsMovementsUrl';
import { collectibleTypeQueryKey } from '../collectibles/constants';
import { trackEvent } from '../analytics/trackEvent';
import { usePlayerById } from './usePlayersList';
import { playersCollectibleType } from './constants';
import { showError } from 'services/toaster';
import { marketplaceHelpers } from 'services/marketplaceHelpers';

export function useCommonPlayersActions() {
  const { getFavoriteIdByEntityId, isUserAtFavoritesLimit, checkIfFavorite } = useFavoriteIds({ entityType: 'player' });
  const { removeFavoritePlayerWithConfirmation } = useFavoritePlayersMutations();
  const { push } = useRouter();

  return useMemo(
    () => ({
      addFavorite: (playerId: number) => {
        if (isUserAtFavoritesLimit) {
          showError({
            description: (
              <div>
                You&apos;ve reached your plan&apos;s limit for favorites.
                <a
                  href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Remove a favorite or upgrade your plan to add more favorites.
                </a>
              </div>
            ),
          });
          return;
        }
        addFavoritePlayer({ playerId });
        trackEvent({
          eventName: 'FAVORITES_PLAYERS_ADD_DIALOG_OPENED',
          playerId,
        });
      },
      removeFavorite: (playerId: number) => {
        const id = getFavoriteIdByEntityId(playerId);
        if (!id) {
          return;
        }
        trackEvent({
          eventName: 'FAVORITES_PLAYERS_REMOVE_DIALOG_OPENED',
          playerId,
        });
        return removeFavoritePlayerWithConfirmation({ id });
      },
      checkIfFavorite,
      getForSaleUrl: (query: string | null | undefined) =>
        query ? marketplaceHelpers.makeSearchUrl({ query, marketplace: 'ebay_jobs', activeOnly: true }) : null,
      trackForSaleClick: () => {
        trackEvent({
          eventName: 'SALES_MARKETPLACE_LINK_CLICKED',
          marketplace: 'ebay_jobs', // NOTE: the only supported at the moment
          collectibleType: playersCollectibleType,
        });
      },
      goToStatsMovements: (playerId: number, otherParams: Partial<MakeStatsMovementsUrlParams> = {}) => {
        const href = makeStatsMovementsUrl({
          filters: {
            ...(otherParams?.filters ?? {}),
            playerIds: [playerId],
          },
          dataFocus: otherParams?.dataFocus ?? null,
          groupBy: otherParams?.groupBy ?? null,
          period: otherParams?.period ?? null,
          otherQSParams: {
            [collectibleTypeQueryKey]: playersCollectibleType,
          },
        });
        push(href);
      },
    }),
    [removeFavoritePlayerWithConfirmation, push, isUserAtFavoritesLimit, checkIfFavorite, getFavoriteIdByEntityId]
  );
}

export interface CommonPlayerActions {
  addFavorite: () => void;
  removeFavorite: () => void;
  isFavorite: boolean;
  forSaleUrl: string | null;
  trackForSaleClick: () => void;
  goToStatsMovements: (otherParams?: Partial<MakeStatsMovementsUrlParams>) => void;
}

export function useCommonPlayerActions({ playerId }: { playerId: number | null | undefined }) {
  const actions = useCommonPlayersActions();
  const { player } = usePlayerById({ playerId: String(playerId) }, { enabled: !!playerId });
  const forSaleQuery = player?.query;

  return useMemo<CommonPlayerActions>(
    () => ({
      addFavorite: () => playerId && actions.addFavorite(playerId),
      removeFavorite: () => playerId && actions.removeFavorite(playerId),
      isFavorite: playerId ? actions.checkIfFavorite(playerId) : false,
      forSaleUrl: actions.getForSaleUrl(forSaleQuery),
      trackForSaleClick: () => playerId && actions.trackForSaleClick(),
      goToStatsMovements: (otherParams = {}) => playerId && actions.goToStatsMovements(playerId, otherParams),
    }),
    [actions, playerId, forSaleQuery]
  );
}
