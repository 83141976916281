import React, { forwardRef } from 'react';
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import classes from './Button.module.scss';

export type ButtonProps = Omit<AntButtonProps, 'size'> & {
  minWidth?: number;
  faIcon?: IconDefinition;
  faIconRight?: IconDefinition;
  size?: Size;
  align?: 'start';
};

export type Size = AntButtonProps['size'] | 'tiny';

export default forwardRef<HTMLElement, ButtonProps>(function Button(
  {
    className,
    size = 'middle',
    shape = 'round',
    type = 'default',
    minWidth = 212,
    faIcon,
    faIconRight,
    icon,
    children,
    disabled,
    loading,
    href,
    align,
    ...otherProps
  }: ButtonProps,
  ref
) {
  return (
    <AntButton
      {...otherProps}
      disabled={disabled}
      loading={loading}
      href={disabled ? undefined : href}
      ref={ref}
      className={clsx(
        classes.button,
        {
          [classes.tiny]: size === 'tiny',
          [classes.alignStart]: align === 'start',
          [classes.antBtnSmWithIconPadding]: size === 'small' && faIcon,
          [classes.antBtnSmWithIconRightPadding]: size == 'small' && faIconRight,
          [classes.antBtnWithIconPadding]: size !== 'small' && faIcon,
          [classes.antBtnWithIconRightPadding]: size !== 'small' && faIconRight,
        },
        className
      )}
      shape={shape}
      type={type}
      size={size === 'tiny' ? 'small' : size}
      style={minWidth ? { minWidth, ...(otherProps.style ?? {}) } : otherProps.style}
      icon={
        icon ??
        (faIcon ? <FontAwesomeIcon className={clsx(classes.icon, classes.iconLeft)} icon={faIcon} /> : undefined)
      }
    >
      {children}
      {!!faIconRight && <FontAwesomeIcon className={clsx(classes.icon, classes.iconRight)} icon={faIconRight} />}
    </AntButton>
  );
});
