import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, // 1 hour -- how long do we keep data that is not currently displayed
      //TODO temporary disabling retrying as we are having a lot of requests triggered against sc-api
      // retry: (failureCount, error) => {
      //   if (isApiError(error) && error.status < 500) {
      //     // do not retry on requests that are unauthenticated, bad, etc.
      //     return false;
      //   }
      //   if (isAbortError(error)) {
      //     return false;
      //   }
      //   return failureCount <= 3;
      // },
      retry: false,
      retryOnMount: false,
    },
  },
});

export default queryClient;
