import qs from 'qs';
import { SalePlatform } from '@sportscardinvestor/schemas';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { CollectibleType, isSportsCardCollectibleType } from '../../../sci-ui-components/types/collectibleType';
import {
  AdvancedChartDataFilters,
  ChartData,
  CollectiblesChartStats,
  ChartDataSeller,
} from '../../../sci-ui-components/types/chartData';
import { Collectible } from '../../../sci-ui-components/types/collectible';
import { IsoDateRange, MaybeIsoDate } from '../../../sci-ui-components/utils/date';
import { ApiChartData, ApiCollectible, ApiCollectiblesChartStats } from '../types';
import getCollectibleTypeParam from '../utils/getCollectibleTypeParam';
import collectibleFromApi from '../collectibles/transformers/collectibleFromApi';
import { RatioTimespan } from '../ratios/types';
import { RatioType } from '../../../features/ratios/types';
import fetchFromSciApi from '../fetchFromSciApi';
import chartDataFromApi from './transformers/chartDataFromApi';
import chartsStatsFromApi from './transformers/chartsStatsFromApi';
import populationCountChartDataFromApi from './transformers/populationCountChartDataFromApi';
import { PopulationChartDataPoint } from './types';

export type { PopulationChartDataPoint } from './types';

export interface GetCollectiblesChartDataParams {
  collectibleType: CollectibleType;
  dateRange: IsoDateRange;
  collectibleIds: number[];
  advancedFilters?: AdvancedChartDataFilters;
  isPublic?: boolean | undefined;
  partiallyIgnoreDateRange?: boolean;
}

interface GetCollectiblesChartDataPayload {
  returnRatios: boolean;
  searchType: 'PCC';
  startDate: MaybeIsoDate;
  endDate: MaybeIsoDate;
  id: string; // comma sparated
  excludeAuctions?: boolean;
  excludeBuyItNow?: boolean;
  excludeNoSellerFeedback?: boolean;
  excludePlatformsFromChartResults?: SalePlatform[];
  excludeSaleWithMakeOffer?: boolean;
  excludeSellersFromChartResults?: ChartDataSeller[];
  partiallyIgnoreDateRange?: boolean;
}

export type ApiChartResultsWaxRatios = {
  waxes: [ApiCollectible, ApiCollectible];
  span: RatioTimespan;
  type: 'box_type';
};

export type ApiChartResultsCardRatios = {
  cards: [ApiCollectible, ApiCollectible];
  span: RatioTimespan;
  type: RatioType;
};

export interface ApiChartResultsCards {
  cards: ApiCollectible[];
  chartData: ApiChartData[];
  individualCardStats: ApiCollectiblesChartStats;
  ratios: ApiChartResultsCardRatios[];
}

export type ChartRatios = ApiChartResultsCardRatios | ApiChartResultsWaxRatios;

export interface ApiChartResultsWax {
  waxes: ApiCollectible[];
  chartData: ApiChartData[];
  individualSealedWaxStats: ApiCollectiblesChartStats;
  ratios: ApiChartResultsWaxRatios[];
}

export interface GetCollectiblesChartDataResponse {
  collectibles: Collectible[];
  chartData: ChartData[];
  collectibleStats: CollectiblesChartStats;
  ratios: ChartRatios[];
}

export async function getCollectiblesChartData(
  {
    collectibleIds,
    collectibleType,
    dateRange: [isoStartDate, isoEndDate],
    advancedFilters,
    isPublic,
    partiallyIgnoreDateRange = false,
  }: GetCollectiblesChartDataParams,
  signal: AbortSignal | undefined
): Promise<GetCollectiblesChartDataResponse> {
  const typeParam = getCollectibleTypeParam(collectibleType);
  const excludeSellers: ChartDataSeller[] = advancedFilters?.excludeSellers ? [...advancedFilters?.excludeSellers] : [];
  if (excludeSellers.includes('pwcc_auctions')) {
    excludeSellers.push('PWCC');
  }
  const payload: GetCollectiblesChartDataPayload = {
    returnRatios: true,
    partiallyIgnoreDateRange,
    searchType: 'PCC',
    startDate: isoStartDate,
    endDate: isoEndDate,
    id: collectibleIds.join(','),
    // advanced filters
    excludeAuctions: advancedFilters?.saleType?.excludeAuctions,
    excludeBuyItNow: advancedFilters?.saleType?.excludeBuyItNow,
    excludeSaleWithMakeOffer: advancedFilters?.saleType?.excludeSaleWithMakeOffer,
    excludePlatformsFromChartResults: advancedFilters?.excludePlatforms,
    excludeSellersFromChartResults: excludeSellers,
    excludeNoSellerFeedback: advancedFilters?.seller?.excludeNoSellerFeedback,
  };

  const response = isPublic
    ? await fetchFromSciApi<ApiChartResultsCards | ApiChartResultsWax>(`/${typeParam}/search/public`, {
        method: 'POST',
        body: JSON.stringify(payload),
        signal,
      })
    : await authenticatedFetchFromSciApi<ApiChartResultsCards | ApiChartResultsWax>(`/${typeParam}/search`, {
        method: 'POST',
        body: JSON.stringify(payload),
        signal,
      });
  const isSportsCardCollectible = isSportsCardCollectibleType(collectibleType);

  const { items, stats, ratios } = isSportsCardCollectible
    ? {
        items: (response as ApiChartResultsCards)?.cards,
        stats: (response as ApiChartResultsCards)?.individualCardStats,
        ratios: (response as ApiChartResultsCards)?.ratios,
      }
    : {
        items: (response as ApiChartResultsWax)?.waxes,
        stats: (response as ApiChartResultsWax)?.individualSealedWaxStats,
        ratios: (response as ApiChartResultsWax)?.ratios,
      };

  const collectibleIdFromResults = items.map((i) => i.id);
  return {
    collectibles: items?.map((apiItem) => collectibleFromApi(apiItem, collectibleType)),
    chartData: chartDataFromApi(response.chartData, collectibleIdFromResults),
    collectibleStats: chartsStatsFromApi(stats),
    ratios,
  };
}

export interface GetPopulationChartDataParams {
  collectibleType: CollectibleType;
  dateRange: IsoDateRange;
  collectibleId: number;
}

export type GetPopulationChartDataResponse = PopulationChartDataPoint[];

export async function getPopulationChartData(
  { collectibleId, dateRange: [isoStartDate, isoEndDate] }: GetPopulationChartDataParams,
  signal: AbortSignal | undefined
): Promise<GetPopulationChartDataResponse> {
  const apiItems = await authenticatedFetchFromSciApi<GetPopulationChartDataResponse>(
    `/daily-pop-count/chart${qs.stringify(
      {
        cardId: collectibleId,
        startDate: isoStartDate,
        endDate: isoEndDate,
      },
      {
        addQueryPrefix: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  return populationCountChartDataFromApi(apiItems);
}
