import FilterContainer, { FilterContainerProps } from '../../sci-ui-components/filters/FilterContainer/FilterContainer';
import { SportsSingleSelect, SportsSingleSelectProps } from './SportsSingleSelect';

export type SportsSingleSelectFilterProps = Partial<FilterContainerProps> &
  Pick<
    SportsSingleSelectProps,
    'value' | 'onChange' | 'useAdminApi' | 'allowOther' | 'noValueLabel' | 'showSearch' | 'notEmpty'
  >;

export function SportsSingleSelectFilter({
  onChange,
  label = 'Sport',
  id = 'sport-filter',
  disabled,
  value,
  useAdminApi,
  allowOther,
  noValueLabel,
  showSearch,
  notEmpty,
  ...other
}: SportsSingleSelectFilterProps) {
  return (
    <FilterContainer {...other} label={label} id={id} disabled={disabled}>
      <SportsSingleSelect
        onChange={onChange}
        value={value}
        useAdminApi={useAdminApi}
        allowOther={allowOther}
        disabled={disabled}
        noValueLabel={noValueLabel}
        showSearch={showSearch}
        notEmpty={notEmpty}
      />
    </FilterContainer>
  );
}
