import { useMutation, useQueryClient } from 'react-query';
import { CollectibleType } from '@sportscardinvestor/schemas';
import { trackEvent } from '../analytics/trackEvent';
import { getUseAlertIdsQueryKey } from './useAlertIds';
import { mmApiClient, MmApiInput, MmApiOutput } from '@/services/mmApiX';
import usePriceAlertsPaginated, { getListAlertsQueryKeyBase } from 'features/alerts/usePriceAlerts';
import { showError, showSuccess } from 'services/toaster';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { getLimitForMembershipTierPermissionCategory } from 'features/user/utils';
import useUser from 'features/user/useUser';
import { EditPriceAlertResponse } from 'sci-ui-components/types/priceAlert';
import useCollectibleType from 'features/collectibles/useCollectibleType';
import { displayLimitReachedMessageForUserAndCategory } from 'features/user/membership-tier-messaging';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

export type CreateAlertInput = Exclude<MmApiInput['private']['alerts']['create'], void>;
type CreateAlertOutput = Exclude<MmApiOutput['private']['alerts']['create'], void>;
type UpdateAlertInput = Exclude<MmApiInput['private']['alerts']['update'], void>;
type ReactivateAlertInput = Exclude<MmApiInput['private']['alerts']['reactivate'], void>;
type DeleteAlertInput = Exclude<MmApiInput['private']['alerts']['delete'], void>;

async function doNothing({} = {}): Promise<EditPriceAlertResponse> {
  return Promise.resolve({
    id: -1,
    threshold: 'Below',
    name: '',
    createdAt: '',
    updatedAt: '',
    triggeredAt: null,
    userId: -1,
    alertPrice: -1,
    status: 'Expired',
    collectibleType: 'sports-card',
    collectibleId: -1,
  });
}

export default function usePriceAlertMutations() {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const { collectibleType } = useCollectibleType();
  const { data: alerts } = usePriceAlertsPaginated({ filters: { collectibleType } });
  const { convertPriceToUSD } = useCurrencyFormatter();

  function isUserAtAlertsLimit(): boolean {
    const limit = getLimitForMembershipTierPermissionCategory(user, 'alerts');

    return limit !== Infinity && !!alerts && (alerts?.totalCount ?? 0) >= limit;
  }

  function invalidateAlertQueries({ collectibleType }: { collectibleType: CreateAlertInput['collectibleType'] }) {
    queryClient.invalidateQueries(getListAlertsQueryKeyBase(collectibleType));
    queryClient.invalidateQueries(getUseAlertIdsQueryKey({ collectibleType }));
  }

  const createAlert = useMutation(
    [`create-price-alert`],
    (params: Omit<CreateAlertInput, 'value'> & { priceInSelectedCurrency: number }): any => {
      if (isUserAtAlertsLimit()) {
        displayLimitReachedMessageForUserAndCategory(user, 'alerts');
        return doNothing({});
      }

      return mmApiClient.private.alerts.create.mutate({
        ...params,
        value: convertPriceToUSD({ value: params.priceInSelectedCurrency }),
      });
    },
    {
      onSuccess: (data: CreateAlertOutput) => {
        if (isUserAtAlertsLimit()) {
          return;
        }
        invalidateAlertQueries({
          collectibleType: data.alert?.collectible?.collectibleType,
        });
        showSuccess({
          description: 'Successfully created new alert',
        });

        trackEvent({
          eventName: 'ALERTS_CREATED',
          collectibleId: data.alert.collectible.id,
          collectibleType,
          alertId: data.alert.id,
        });
      },
      onError: () => {
        showError({
          description: 'Failed to create new alert. Please try again.',
        });
      },
    }
  );

  const editAlert = useMutation(
    ['edit-price-alert'],
    (
      params: Omit<UpdateAlertInput, 'value'> & {
        priceInSelectedCurrency: number;
        collectibleType: CollectibleType;
        collectibleId: number;
      }
    ) =>
      mmApiClient.private.alerts.update.mutate({
        ...params,
        value: convertPriceToUSD({ value: params.priceInSelectedCurrency }),
      }),
    {
      onSuccess: (_, variables) => {
        invalidateAlertQueries({
          collectibleType: variables.collectibleType,
        });

        showSuccess({
          description: 'Successfully updated alert',
        });
        trackEvent({
          eventName: 'ALERTS_UPDATED',
          collectibleId: variables.collectibleId,
          collectibleType,
          alertId: variables.id,
        });
      },
      onError: () => {
        showError({
          description: 'Failed to update alert. Please try again.',
        });
      },
    }
  );

  const deleteAlert = useMutation(
    ['delete-price-alert'],
    (
      params: DeleteAlertInput & {
        collectibleType: CollectibleType;
      }
    ) => mmApiClient.private.alerts.delete.mutate(params).then(() => undefined),
    {
      onSuccess: async (_, variables) => {
        invalidateAlertQueries({
          collectibleType: variables.collectibleType,
        });
        showSuccess({
          description: 'Successfully deleted alert',
        });
        trackEvent({
          eventName: 'ALERTS_DELETED',
          alertId: variables.id,
        });
      },
      onError: () => {
        showError({
          description: 'Failed to delete alert. Please try again.',
        });
      },
    }
  );

  const deleteWithConfirmation = async (
    params: DeleteAlertInput & {
      collectibleType: CollectibleType;
    }
  ) => {
    waitForConfirmation({
      title: 'Delete price alert',
      description: `Are you sure you want to delete this price alert?`,
      onConfirm: async () => await deleteAlert.mutateAsync(params),
      onCancel: () =>
        trackEvent({
          eventName: 'ALERTS_DELETE_CANCELLED',
          alertId: params.id,
        }),
    });
  };

  const reactivateAlert = useMutation(
    ['reactivate-price-alert'],
    (
      params: ReactivateAlertInput & {
        collectibleType: CollectibleType;
        collectibleId: number;
      }
    ) => mmApiClient.private.alerts.reactivate.mutate(params),
    {
      onSuccess: (_, variables) => {
        invalidateAlertQueries({
          collectibleType: variables.collectibleType,
        });
        showSuccess({
          description: 'Successfully reactivated alert',
        });
        trackEvent({
          eventName: 'ALERTS_REACTIVATED',
          collectibleId: variables.collectibleId,
          collectibleType,
          alertId: variables.id,
        });
      },
      onError: () => {
        showError({
          description: 'Failed to reactivate alert. Please try again.',
        });
      },
    }
  );

  return {
    createAlert,
    editAlert,
    deleteWithConfirmation,
    reactivateAlert,
  };
}
