import { MmApiInput, MmApiOutput, MmApiQueryOptions, mmApiClient, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

type UseAvailableBoxTypesByCollectibleInput =
  MmApiInput['private']['collectibles']['filters']['availableBoxTypesForSealed'];
type UseAvailableBoxTypesByCollectibleOutput =
  MmApiOutput['private']['collectibles']['filters']['availableBoxTypesForSealed'];
type UseAvailableBoxTypesByCollectibleKey = [
  'private.collectibles.filters.availableBoxTypesForSealed',
  UseAvailableBoxTypesByCollectibleInput
];

export function useAvailableBoxTypes(
  input: UseAvailableBoxTypesByCollectibleInput,
  options?: MmApiQueryOptions<UseAvailableBoxTypesByCollectibleOutput, UseAvailableBoxTypesByCollectibleKey>
) {
  const key: UseAvailableBoxTypesByCollectibleKey = ['private.collectibles.filters.availableBoxTypesForSealed', input];
  const result = useAuthenticatedMMAPIQuery(
    key,
    () => mmApiClient.private.collectibles.filters.availableBoxTypesForSealed.query(input),
    options
  );

  return result;
}
