import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import SimpleCollapsible from '../SimpleCollapsible/SimpleCollapsible';
import Button from '../buttons/Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Checkbox } from '../forms/Checkboxes/Checkboxes';
import { TableProps, Column } from './types';
import { getColGroups } from './utils';

import classes from './Table.module.scss';
import { useBreakpoint } from 'sci-ui-components/hooks/useBreakpoint';

export type { TitleProps, TableProps, OnSortChangeFn, Column, FilterOptions } from './types';

export default function Table<
  TItem extends object,
  TRowData extends object = TItem,
  TRowKey extends string | number = number,
  TSortKey extends string = string,
  TFilters extends { [key: string]: any[] } = { [key: string]: any[] }
>({
  className,
  gridClass,
  items,
  getRowData = (i) => i as unknown as TRowData,
  columns,
  subHeader,
  footer,
  getRowKey,
  stickyHeader = false,
  hideHeader = false,
  hasGroups = false,
  sortedBy,
  sortDirection,
  defaultSortDirection,
  headerClassName,
  onSortChange,
  expandedRowKeys,
  onExpandedChange,
  isRowExpandable,
  renderExpandableContent,
  emptyMessage = 'No items',
  isLoading,
  onRowClick,
  onRowHover,
  getRowClassName,
  noBackgroundColor = false,
  filters,
  onFiltersChange,
  notScrollable = false,
  allowMultiSelect = false,
  onMultiSelectChange,
  selectedIds,
  rowSpacerClassName,
}: TableProps<TItem, TRowData, TRowKey, TSortKey, TFilters>) {
  const hoveringRowKeyRef = useRef<TRowKey | null>(null);

  const rows = items.map((item) => getRowData(item));
  const rowKeys = rows.map((row, index) => getRowKey(row, index));

  const { selectedRows, stringifiedSelectedIds } = useMemo<{
    selectedRows: { [key: string]: boolean };
    stringifiedSelectedIds: string[];
  }>(() => {
    if (!selectedIds) {
      return { selectedRows: {}, stringifiedSelectedIds: [] };
    }
    return {
      selectedRows: selectedIds.reduce((acc, val) => ({ ...acc, [val]: true }), {}),
      stringifiedSelectedIds: (selectedIds || []).map((key) => String(key)),
    };
  }, [selectedIds]);

  const enhancedColumns = allowMultiSelect
    ? [
        {
          key: 'select',
          width: '60px',
          renderTitle: () => {
            const isAllSelected = Object.values(selectedRows).filter(Boolean).length === rowKeys.length;
            return (
              <div className={classes.checkboxContainer}>
                <Checkbox
                  checked={isAllSelected}
                  size={'small'}
                  onClick={() => {
                    if (isAllSelected) {
                      onMultiSelectChange?.([]);
                    } else {
                      onMultiSelectChange?.(rowKeys.map((key) => String(key)));
                    }
                  }}
                />
              </div>
            );
          },
          renderData: (_: TItem, { rowKey }: { rowKey: TRowKey }) => (
            <div className={classes.checkboxContainer}>
              <Checkbox
                checked={selectedRows[rowKey]}
                size={'small'}
                onClick={(event) => {
                  event.stopPropagation();
                  if (selectedRows[rowKey]) {
                    onMultiSelectChange?.(stringifiedSelectedIds.filter((key) => key !== String(rowKey)));
                  } else {
                    onMultiSelectChange?.([...(stringifiedSelectedIds || []), String(rowKey)]);
                  }
                }}
              />
            </div>
          ),
        },
        ...columns,
      ]
    : columns;
  const filteredColumns = enhancedColumns.filter((c) => !!c) as Column<TRowData, TRowKey, TSortKey, TFilters>[];
  const gridTemplateColumns = filteredColumns.map((c) => c.width ?? 'auto').join(' ');
  const { groups, startIndicesSet, endIndicesSet } = getColGroups(filteredColumns, hasGroups);

  const subHeaderGridRowStart = !hideHeader ? 2 : 1;
  const itemsGridRowStart = subHeaderGridRowStart + (subHeader ? 1 : 0);

  const header = !hideHeader ? (
    <>
      <div
        className={clsx(
          {
            [classes.headerCellSticky]: stickyHeader,
          },
          headerClassName
        )}
        style={{
          gridRowStart: 1,
          gridColumnStart: 1,
          gridColumnEnd: `span ${filteredColumns.length}`,
        }}
      />
      {filteredColumns.map(
        (
          { key, renderTitle, sortKey, filterKey, filterOptions, minWidth, headerClassName, isSingleSelectFilter },
          index
        ) => {
          return (
            <div
              key={key}
              className={clsx(
                classes.headerCell,
                {
                  [classes.headerCellSticky]: stickyHeader,
                  [classes.columnHighlighted]: !!sortKey && sortKey === sortedBy,
                },
                headerClassName
              )}
              style={{ gridColumnStart: index + 1, gridRowStart: 1, minWidth }}
            >
              {renderTitle?.({
                sortDirection: !!sortKey && sortedBy === sortKey ? sortDirection : null,
                isSortable: !!sortKey,
                onSortChange,
                sortKey,
                rows,
                rowKeys,
                filterKey,
                filterOptions,
                filters,
                onFiltersChange,
                isSingleSelectFilter,
                defaultSortDirection,
              })}
            </div>
          );
        }
      )}
    </>
  ) : null;

  const handleHover = (rowKey: TRowKey | null) => {
    if (hoveringRowKeyRef.current !== rowKey) {
      hoveringRowKeyRef.current = rowKey;
      onRowHover?.(rowKey);
    }
  };

  const breakpoint = useBreakpoint(true);
  const justifyLeftExpandButton = () => {
    switch (breakpoint) {
      case 'md':
        return true;
      case 'sm':
        return true;
      case 'xs':
        return true;
      default:
        return false;
    }
  };

  const renderedRows = rows.reduce<JSX.Element[]>((acc, rowData, itemIndex) => {
    const rowIndex = acc.length;
    const rowKey = rowKeys[itemIndex];
    const gridRowStart = rowIndex + itemsGridRowStart;
    acc.push(
      <React.Fragment key={rowKey}>
        {groups.map((group, groupIndex) => (
          <div
            key={groupIndex}
            className={clsx(
              classes.group,
              {
                [classes.groupBackground]: !noBackgroundColor,
              },
              getRowClassName?.(rowData, itemIndex)
            )}
            style={{ gridRowStart, gridColumnStart: group.start, gridColumnEnd: `span ${group.span}` }}
          />
        ))}
        {filteredColumns.map(
          ({ renderData, groupId, key: columnKey, sortKey, notClickable, onClick, cellClassName }, columnIndex) => {
            const isClickable = (!!onRowClick || !!onClick) && !notClickable;
            return (
              <div
                role={isClickable ? 'button' : 'cell'}
                onClick={
                  isClickable
                    ? () => {
                        if (onClick) {
                          onClick(rowData);
                        } else {
                          onRowClick?.(rowData, rowKey);
                        }
                      }
                    : undefined
                }
                key={columnKey}
                className={clsx(
                  classes.dataCell,
                  {
                    [classes.noGroupCell]: !groupId && hasGroups,
                    [classes.groupStartCell]: startIndicesSet.has(columnIndex),
                    [classes.groupEndCell]: endIndicesSet.has(columnIndex),
                    [classes.clickableCell]: isClickable,
                    [classes.columnHighlighted]: !!sortKey && sortedBy === sortKey,
                    [classes.actionsCell]: columnKey === 'actions' && endIndicesSet.has(columnIndex),
                  },
                  cellClassName
                )}
                style={{ gridRowStart, gridColumnStart: columnIndex + 1 }}
                onMouseEnter={() => handleHover(rowKey)}
                onMouseLeave={() => handleHover(null)}
              >
                {renderData(rowData, { rowKey })}
              </div>
            );
          }
        )}
      </React.Fragment>
    );

    const isExpandable = !!renderExpandableContent && (!isRowExpandable || isRowExpandable(rowData, itemIndex));
    if (isExpandable) {
      const isExpanded = !!expandedRowKeys?.includes(rowKey);
      acc.push(
        <div
          key={`${rowKey}-expandable`}
          className={clsx(classes.expandableRow, {
            [classes.expandButtonBreakpoint]: justifyLeftExpandButton(),
          })}
          style={{
            gridRowStart: gridRowStart + 1,
            gridColumnStart: 1,
            gridColumnEnd: `span ${filteredColumns.length}`,
          }}
        >
          <SimpleCollapsible className={classes.expandableContent} isExpanded={isExpanded}>
            {renderExpandableContent(rowData, { isExpanded })}
          </SimpleCollapsible>
          <Button
            className={classes.expandButton}
            size="small"
            htmlType="button"
            shape="round"
            faIcon={isExpanded ? faCaretUp : faCaretDown}
            onClick={() => onExpandedChange?.(rowKey)}
            minWidth={80}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </Button>
        </div>
      );
    }

    acc.push(
      <div
        key={`${rowKey}-spacer`}
        className={clsx(classes.rowSpacer, rowSpacerClassName)}
        style={{
          gridRowStart: gridRowStart + 1 + (isExpandable ? 1 : 0),
          gridColumnStart: 1,
          gridColumnEnd: `span ${filteredColumns.length}`,
        }}
      />
    );

    return acc;
  }, []);

  if (!items.length && !isLoading) {
    renderedRows.push(
      <div
        key="empty-message"
        className={classes.emptyMessage}
        style={{
          gridRowStart: itemsGridRowStart,
          gridColumnStart: 1,
          gridColumnEnd: `span ${filteredColumns.length}`,
        }}
      >
        {emptyMessage}
      </div>
    );
  }

  if (isLoading) {
    renderedRows.push(
      <div
        key="loading-indicator"
        className={classes.loadingIndicator}
        style={{
          gridRowStart: itemsGridRowStart,
          gridColumnStart: 1,
          gridColumnEnd: `span ${filteredColumns.length}`,
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        {
          [classes.scrollable]: !notScrollable,
        },
        className
      )}
    >
      <div className={clsx(classes.gridContainer, gridClass)} style={{ gridTemplateColumns }}>
        {renderedRows}
        {!!footer && (
          <div
            key="footer"
            style={{
              gridRowStart: renderedRows.length + 1,
              gridColumnStart: 1,
              gridColumnEnd: `span ${filteredColumns.length}`,
            }}
          >
            {footer}
          </div>
        )}

        {/* NOTE: header is rendered after rows to prevent rows displaying on top of the sticky header */}
        {header}
        {!!subHeader && (
          <div
            key="subHeader"
            className={classes.subHeader}
            style={{
              gridRowStart: subHeaderGridRowStart,
              gridColumnStart: 1,
              gridColumnEnd: `span ${filteredColumns.length}`,
            }}
          >
            {subHeader}
          </div>
        )}
      </div>
    </div>
  );
}
