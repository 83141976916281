import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { CollectibleType, CustomCollectible } from '@sportscardinvestor/schemas';
import {
  extractSetYearFromSetName,
  getCollectibleSetDisplayName,
  getCollectibleSetYear,
  getCollectibleSportName,
  isSealedCollectible,
  isSportsCardCollectible,
} from '@sportscardinvestor/collectible-helpers';
import FieldLabelBox from '@/sci-ui-components/forms/FieldLabelBox/FieldLabelBox';
import { SetsAutoComplete } from '@/features/sets/SetsAutoComplete';
import { getSetDisplayText } from '@/features/sets/useSetsList';

export const setFormSchema = z.object({
  sportName: z.string().trim().default(''),
  setYear: z.string().trim().default(''),
  setName: z
    .string({
      required_error: 'Please enter set name',
    })
    .trim()
    .min(1, 'Please enter set name'),
  setId: z.number().nullable().default(null),
});

type SetFormSchema = z.input<typeof setFormSchema>;
type SetFormPayload = z.output<typeof setFormSchema>;

export function SetField({
  className,
  onChange,
  collectibleType,
}: {
  className?: string;
  onChange?: () => void;
  collectibleType: CollectibleType;
}) {
  const form = useFormContext<SetFormSchema, unknown, SetFormPayload>();

  return (
    <Controller
      name="setName"
      control={form.control}
      render={({ field, fieldState }) => (
        <FieldLabelBox
          className={className}
          label="Set"
          fieldId="setName"
          error={fieldState.error?.message}
          variant="neater"
        >
          <SetsAutoComplete
            id="setName"
            value={field.value}
            collectibleType={collectibleType}
            onSelect={(data) => {
              if (!data) {
                form.setValue('setId', null);
                form.setValue('setName', '');
                form.setValue('setYear', '');
              } else {
                form.setValue('setId', data.id);
                form.setValue('setName', getSetDisplayText(data));
                form.setValue('setYear', data.year ?? undefined);
                const { sportName } = form.getValues();
                if (!sportName?.length && data.sport) {
                  form.setValue('sportName', data.sport.name);
                }
              }
              onChange?.();
            }}
            onChange={(value) => {
              form.setValue('setId', null);
              form.setValue('setName', value);
              const setYear = extractSetYearFromSetName(value);
              form.setValue('setYear', setYear ?? '');
              onChange?.();
            }}
          />
        </FieldLabelBox>
      )}
    />
  );
}

export function getSetInitialValues({ collectible }: { collectible: CustomCollectible | null }): SetFormSchema {
  if (!collectible) {
    return {
      setName: '',
    };
  }
  return {
    setName: getCollectibleSetDisplayName(collectible),
    sportName: getCollectibleSportName(collectible) ?? '',
    setId:
      (isSportsCardCollectible(collectible) || isSealedCollectible(collectible)) && collectible.set?.id
        ? Number(collectible.set?.id)
        : null,
    setYear: getCollectibleSetYear(collectible) ?? extractSetYearFromSetName(collectible.setName) ?? '',
  };
}
