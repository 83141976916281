export interface Filters {
  sportIds?: number[] | null;
  playerIds?: number[] | null;
  setYears?: number[] | null;
  setNames?: string[] | null;
  setIds?: number[] | null;
  isRookieOnly?: boolean | null;
  variationIds?: number[] | null;
  variationNames?: string[] | null;
  setVariationIds?: number[] | null;
  gradeIds?: number[] | null;
  boxTypeIds?: number[] | null;
  gradedCardsOnly?: boolean | null;
}

export const defaultFilters: Filters = {
  sportIds: null,
  playerIds: null,
  setYears: null,
  setNames: null,
  setIds: null,
  isRookieOnly: null,
  variationIds: null,
  variationNames: null,
  setVariationIds: null,
  gradeIds: null,
  boxTypeIds: null,
  gradedCardsOnly: null,
};
