import { useMemo, useCallback } from 'react';
import {
  useAuthenticatedMMAPIInfiniteQuery,
  useAuthenticatedMMAPIQuery,
  MmApiInfiniteQueryOptions,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import { getOSTextSearchParams } from '../sales/helpers';

export type UseCollectiblesSearchInput = MmApiInput['private']['collectibles']['search'];
export type UseCollectiblesSearchSortByValue = Exclude<UseCollectiblesSearchInput['sort'], void>[number]['sortBy'];
export type UseCollectiblesSearchOutput = MmApiOutput['private']['collectibles']['search'];
export type CollectiblesSearchItem = Exclude<UseCollectiblesSearchOutput, void | null>['items'][number];
export type Collectible = Omit<CollectiblesSearchItem['item'], 'indexedAt'>;

export const useCollectiblesSearchKeyPrefix = 'private.collectibles.search';
export type UseCollectiblesSearchQueryKey = [typeof useCollectiblesSearchKeyPrefix, UseCollectiblesSearchInput];

const useCollectiblesSearchDefaultSort: UseCollectiblesSearchInput['sort'] = [
  {
    sortBy: 'stats.last14.totalSalesCount',
    sortDirection: 'desc',
  },
  {
    sortBy: 'stats.all.endAvgPrice',
    sortDirection: 'desc',
  },
  {
    sortBy: 'id.keyword',
    sortDirection: 'asc',
  },
];

const emptyResults: CollectiblesSearchItem[] = [];

export function useCollectiblesSearchPaginated(
  { sort = useCollectiblesSearchDefaultSort, ...rest }: UseCollectiblesSearchInput,
  options?: MmApiQueryOptions<UseCollectiblesSearchOutput, UseCollectiblesSearchQueryKey>
) {
  const input: UseCollectiblesSearchInput = {
    sort,
    ...rest,
    ...{ searchQueryText: getOSTextSearchParams(rest.searchQueryText || '') },
    disableSpellCheck: true,
  };
  const queryKey: UseCollectiblesSearchQueryKey = [useCollectiblesSearchKeyPrefix, input];
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    async () => mmApiClient.private.collectibles.search.query(input),
    options
  );
  const items = result.data?.items ?? emptyResults;

  return {
    ...result,
    items,
    totalCount: result.data?.totalCount ?? 0,
  };
}

export function useCollectiblesSearchInfinite(
  { sort = useCollectiblesSearchDefaultSort, ...rest }: UseCollectiblesSearchInput,
  options?: MmApiInfiniteQueryOptions<UseCollectiblesSearchOutput, UseCollectiblesSearchQueryKey>
) {
  const input: UseCollectiblesSearchInput = {
    sort,
    ...rest,
    ...{ searchQueryText: getOSTextSearchParams(rest.searchQueryText || '') },
  };
  const queryKey: UseCollectiblesSearchQueryKey = [useCollectiblesSearchKeyPrefix, input];
  const result = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    async ({ pageParam }) =>
      mmApiClient.private.collectibles.search.query({
        ...input,
        disableSpellCheck: !!pageParam || input.disableSpellCheck,
        offset: pageParam ?? 0,
      }),
    {
      ...(options ?? {}),
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage) {
          return null;
        }
        const totalFetched = pages.reduce((acc, page) => acc + page.items.length, 0);
        if (lastPage.totalCount <= totalFetched) {
          return null;
        }
        return totalFetched;
      },
    }
  );
  const pages = result.data?.pages;
  const items = useMemo<CollectiblesSearchItem[]>(
    () =>
      pages?.reduce<{ items: CollectiblesSearchItem[]; set: Set<string> }>(
        (acc, page) => {
          page.items.forEach((item) => {
            if (!acc.set.has(String(item.item.id))) {
              acc.items.push(item);
              acc.set.add(String(item.item.id));
            }
          });
          return acc;
        },
        { items: [], set: new Set() }
      )?.items ?? [],
    [pages]
  );
  const firstPage = result.data?.pages?.[0];
  const { totalCount, spellCheckSuggestions } = useMemo<{ totalCount: number; spellCheckSuggestions: string[] }>(() => {
    if (!firstPage) {
      return {
        spellCheckSuggestions: [],
        totalCount: 0,
      };
    }
    return {
      spellCheckSuggestions: firstPage.verifiedSuggestions,
      totalCount: firstPage.totalCount,
    };
  }, [firstPage]);

  const { hasNextPage, fetchNextPage } = result;
  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    items,
    spellCheckSuggestions,
    totalCount,
    fetchNextPageIfAvailable,
    fetchNextPage,
    hasNextPage,
  };
}
