import { ReactNode, useEffect, useMemo, useState } from 'react';
import { AutoComplete as AntdAutoComplete, Input as AntdInput } from 'antd';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelectPopupContainer } from '../SimpleSelect/useSelectPopupContainer';
import ClearIconButton from '../ClearIconButton/ClearIconButton';
import classes from './AutoComplete.module.scss';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export type AutoCompleteProps<TData = any, TLabel extends string | ReactNode = string> = Omit<
  AutoCompleteControlledProps<TData, TLabel>,
  'onEndReached' | 'endReachedThreshold' | 'hasMore' | 'options'
> & {
  pageSize?: number;
  makeOption: (data: TData) => AutoCompleteOption<TData, TLabel>;
  options: TData[];
};

export function AutoComplete<TData = any>({
  value,
  options,
  makeOption,
  pageSize = 50,
  ...rest
}: AutoCompleteProps<TData, string>) {
  const [maxItemsToDisplay, setMaxItemsToDisplay] = useState(pageSize);
  const filteredOptions = useMemo(() => {
    if (!value) {
      return options.map(makeOption);
    }
    const keywords = value.toLowerCase().trim().replace(/\s+/g, ' ')?.split(' ');
    return options
      .map(makeOption)
      .filter((option) => keywords.every((word) => option.label.toLowerCase().includes(word)));
  }, [value, options, makeOption]);
  const visibleOptions = filteredOptions.slice(0, maxItemsToDisplay);

  useEffect(() => setMaxItemsToDisplay(pageSize), [filteredOptions.length, pageSize]);
  const handleEndReached = useStableFunctionIdentity(() => setMaxItemsToDisplay((v) => v + pageSize));

  return (
    <AutoCompleteControlled<TData, string>
      {...rest}
      value={value}
      options={visibleOptions}
      onEndReached={handleEndReached}
      hasMore={maxItemsToDisplay < filteredOptions.length}
    />
  );
}

export interface AutoCompleteOption<TData = any, TLabel extends string | ReactNode = string> {
  value: string;
  label: TLabel;
  key: string;
  data: TData;
}

export interface AutoCompleteControlledProps<TData = any, TLabel extends string | ReactNode = string> {
  id?: string;
  className?: string;
  value?: string | null;
  onSelect: (option: TData | null) => void;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  readOnly?: boolean;
  // controlled props
  options: AutoCompleteOption<TData, TLabel>[];
  onEndReached?: () => void;
  endReachedThreshold?: number;
  hasMore?: boolean;
}

export function AutoCompleteControlled<TData = any, TLabel extends string | ReactNode = string>({
  id,
  value,
  className,
  onChange,
  onSelect,
  onBlur,
  options,
  disabled,
  placeholder = 'Make a selection or type your own',
  readOnly,
  onEndReached,
  endReachedThreshold = 0,
  hasMore,
  isLoading,
}: AutoCompleteControlledProps<TData, TLabel>) {
  const { getPopupContainer } = useSelectPopupContainer();
  const handleInViewChange = useStableFunctionIdentity((isInView: boolean) => {
    if (isInView && onEndReached) {
      onEndReached();
    }
  });
  const { ref: inViewRef } = useInView({
    skip: !onEndReached || hasMore === false,
    initialInView: false,
    triggerOnce: false,
    onChange: handleInViewChange,
    delay: 100,
  });
  const endReachedTriggerIndex = Math.max(0, options.length - endReachedThreshold - 1);

  const allOptions = options.map((option, i) => ({
    ...option,
    label: <span ref={i === endReachedTriggerIndex ? inViewRef : undefined}>{option.label}</span>,
  }));

  return (
    <div className={clsx(classes.selectWrapper, className)}>
      <AntdAutoComplete
        id={id}
        value={value}
        options={allOptions}
        className={classes.select}
        onSearch={onChange}
        searchValue={value ?? ''}
        onSelect={(_: any, option: AutoCompleteOption<TData, ReactNode>) => onSelect(option.data)}
        allowClear={false}
        disabled={disabled}
        dropdownMatchSelectWidth={false}
        virtual
        getPopupContainer={getPopupContainer}
        onBlur={onBlur}
      >
        <AntdInput
          placeholder={placeholder}
          suffix={
            <div className="flex items-center">
              {isLoading ? <FontAwesomeIcon title="loading" icon={faSpinner} className="animate-spin" /> : null}
              {disabled || readOnly ? undefined : <ClearIconButton onClick={() => onSelect(null)} visible={!!value} />}
            </div>
          }
        />
      </AntdAutoComplete>
    </div>
  );
}
