import { useEffect, useMemo, useState } from 'react';
import { CollectibleType } from '@sportscardinvestor/schemas';
import {
  RawCompletedSaleSearchItem,
  useInfiniteRawCompletedSalesSearch,
} from '../sales-history/useRawCompletedSalesSearch';
import { useCollectiblesSearchInfinite, CollectiblesSearchItem } from '../collectibles/useCollectiblesSearch';
import { trackEvent } from '../analytics/trackEvent';
import { UnifiedSearchResultsType } from './types';
import { useOnValueChange } from '@/hooks/useOnValueChange';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';

export interface UseUnifiedSearchParams {
  collectibleType: CollectibleType;
  searchText: string;
  debounceTimeInMs?: number;
  enableSpellCheck?: boolean;
  enableForEmptySearchText?: boolean;
}

export interface UseUnifiedSearchReturn {
  isDebouncing: boolean;
  hasSearchText: boolean;
  debouncedSearchText: string;
  searchResultsType: UnifiedSearchResultsType;
  toggleSearchResultsType: (searchResultsType: UnifiedSearchResultsType) => void;
  salesHistory: SalesHistoryReturn;
  featured: FeaturedReturn;
}

export type SalesHistoryReturn = SearchReturn<RawCompletedSaleSearchItem>;

export type FeaturedReturn = SearchReturn<CollectiblesSearchItem>;

type SearchReturn<TItem> = {
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPageIfAvailable: () => void;
  spellCheckSuggestions: string[];
  exactMatchItems: TItem[];
  fuzzyMatchItems: TItem[];
};

/**
 * Featured cards and Sales History searches
 */
export function useUnifiedSearch(
  {
    collectibleType,
    searchText,
    debounceTimeInMs = 300,
    enableSpellCheck = false,
    enableForEmptySearchText = false,
  }: UseUnifiedSearchParams,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
): UseUnifiedSearchReturn {
  const [debouncedSearchText, isDebouncing] = useDebouncedValue(searchText, debounceTimeInMs);
  const hasSearchText = !!debouncedSearchText?.trim().length;
  const [searchResultsType, setSearchResultsType] = useState<UnifiedSearchResultsType>('featured');

  const {
    allItems: salesHistoryExactMatchItems,
    extraItems: salesHistoryFuzzyMatchItems,
    verifiedSpellCheckedAlternatives: salesHistorySpellCheckSuggestions,
    hasNextPage: salesHistoryHasNextPage,
    fetchNextPageIfAvailable: salesHistoryFetchNextPageIfAvailable,
    isFetchingNextPage: salesHistoryIsFetchingNextPage,
    isLoading: salesHistoryIsLoading,
  } = useInfiniteRawCompletedSalesSearch(
    {
      titleSearchQueryText: debouncedSearchText,
      sort: [
        {
          sortBy: 'saleDate',
          sortDirection: 'desc',
        },
      ],
      disableSpellCheck: !enableSpellCheck,
    },
    {
      enabled:
        enabled && searchResultsType === 'salesHistory' && (enableForEmptySearchText || !!debouncedSearchText?.length),
    }
  );

  const {
    items: featuredExactMatchItems,
    spellCheckSuggestions: featuredSpellCheckSuggestions,
    hasNextPage: featuredHasNextPage,
    fetchNextPageIfAvailable: featuredFetchNextPageIfAvailable,
    isFetchingNextPage: featuredIsFetchingNextPage,
    isLoading: featuredIsLoading,
  } = useCollectiblesSearchInfinite(
    {
      filters: { collectibleTypes: [collectibleType] },
      searchQueryText: debouncedSearchText,
      limit: 10,
    },
    {
      enabled:
        enabled && searchResultsType === 'featured' && (enableForEmptySearchText || !!debouncedSearchText?.length),
    }
  );

  const salesHistory = useMemo<SalesHistoryReturn>(
    () => ({
      isLoading: salesHistoryIsLoading,
      isFetchingNextPage: salesHistoryIsFetchingNextPage,
      exactMatchItems: salesHistoryExactMatchItems,
      fetchNextPageIfAvailable: salesHistoryFetchNextPageIfAvailable,
      fuzzyMatchItems: salesHistoryHasNextPage ? [] : salesHistoryFuzzyMatchItems,
      hasNextPage: salesHistoryHasNextPage ?? false,
      spellCheckSuggestions: salesHistorySpellCheckSuggestions,
    }),
    [
      salesHistoryExactMatchItems,
      salesHistoryFetchNextPageIfAvailable,
      salesHistoryFuzzyMatchItems,
      salesHistoryHasNextPage,
      salesHistoryIsFetchingNextPage,
      salesHistoryIsLoading,
      salesHistorySpellCheckSuggestions,
    ]
  );

  const featured = useMemo<FeaturedReturn>(
    () => ({
      isLoading: featuredIsLoading,
      isFetchingNextPage: featuredIsFetchingNextPage,
      exactMatchItems: featuredExactMatchItems,
      fetchNextPageIfAvailable: featuredFetchNextPageIfAvailable,
      fuzzyMatchItems: [],
      hasNextPage: featuredHasNextPage ?? false,
      spellCheckSuggestions: featuredSpellCheckSuggestions,
    }),
    [
      featuredExactMatchItems,
      featuredFetchNextPageIfAvailable,
      featuredHasNextPage,
      featuredIsFetchingNextPage,
      featuredIsLoading,
      featuredSpellCheckSuggestions,
    ]
  );

  const hasFeaturedResults = !!featuredExactMatchItems.length;
  const hasFeaturedSpellCheckSuggestions = !!featuredSpellCheckSuggestions.length;
  const isFeaturedResultsType = searchResultsType === 'featured';

  useOnValueChange(hasSearchText, () => {
    if (!hasSearchText) {
      setSearchResultsType('featured');
    }
  }); // NOTE: default to featured results if user clears search
  const shouldSwitchToSalesHistory =
    hasSearchText &&
    !featuredIsLoading &&
    !hasFeaturedResults &&
    (!hasFeaturedSpellCheckSuggestions || !enableSpellCheck);
  useOnValueChange(shouldSwitchToSalesHistory, () => {
    if (isFeaturedResultsType && shouldSwitchToSalesHistory) {
      setSearchResultsType('salesHistory');
    }
  });

  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'SEARCH_QUICK_SEARCH_USED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [debouncedSearchText, collectibleType]);

  return {
    isDebouncing,
    debouncedSearchText,
    hasSearchText,
    searchResultsType,
    toggleSearchResultsType: setSearchResultsType,
    salesHistory,
    featured,
  };
}
