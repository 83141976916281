/**
 * NOTE: why this exists:
 * 1. simple JSON.parse wrapper
 * 2. subscription:
 * - we want to persist values in local storage
 * - we also want to use those persisted values in react (ex. persist settings or category)
 * - there is no way to subsicribe for local storage changes (in contrast to query string for example)
 * - this util wraps local storage's setItem so that setting it in one place will notify all subscribers (ex. useLocalStorageValue hook)
 *  */

export type Listener = (value: any) => void;
const subscribers = new Map<string, Set<Listener>>();

export type StorageType = 'local' | 'session';

export function subscribe(key: string, cb: Listener, storageType: StorageType = 'local') {
  const id = `${storageType}-${key}`;
  if (!subscribers.has(id)) {
    subscribers.set(id, new Set());
  }
  subscribers.get(id)!.add(cb);
  return () => {
    subscribers.get(id)?.delete(cb);
  };
}

export function setItem<TValue extends any = string>(key: string, value: TValue, storageType: StorageType = 'local') {
  if (typeof window === 'undefined') {
    return;
  }
  const valueString = typeof value === 'string' ? value : JSON.stringify(value);
  (storageType === 'session' ? sessionStorage : localStorage).setItem(key, valueString);
  subscribers.get(`${storageType}-${key}`)?.forEach((cb) => cb(value));
}

export function getItem<TValue extends any = string>(key: string, parse = false, storageType: StorageType = 'local') {
  if (typeof window === 'undefined') {
    return null;
  }
  const maybeValueStr = (storageType === 'session' ? sessionStorage : localStorage).getItem(key);
  if (parse && maybeValueStr !== null && typeof maybeValueStr !== 'undefined') {
    try {
      const value = JSON.parse(maybeValueStr);
      return value as TValue;
    } catch (err) {
      console.error(`Failed to parse local storage value ${key}="${maybeValueStr}"`);
      return null;
    }
  }
  return maybeValueStr as TValue | null;
}

export function removeItem(key: string, storageType: StorageType = 'local') {
  if (typeof window === 'undefined') {
    return;
  }
  (storageType === 'session' ? sessionStorage : localStorage).removeItem(key);
}

export enum StorageKey {
  MMAPIAccessToken = 'mm_token',
  MMAPIRefreshToken = 'mm_rt',
  SCIAPIAccessToken = 'sci_token',
}
