import { memo, useMemo } from 'react';
import { Sport } from '@sportscardinvestor/schemas';
import { useSportsListPaginated, useFilterSports } from './useSportsList';
import {
  AutoCompleteControlled,
  AutoCompleteOption,
  AutoCompleteControlledProps,
} from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type SportsAutoCompleteProps = Omit<AutoCompleteControlledProps<Sport>, 'options' | 'makeOption'> & {
  useAdminApi?: boolean;
  allowOther?: boolean;
};
export const SportsAutoComplete = memo(function SportsAutoComplete({
  value,
  useAdminApi,
  allowOther,
  ...other
}: SportsAutoCompleteProps) {
  const { items: allSportItems = [], isLoading } = useSportsListPaginated({
    useAdminApi,
    allowOther,
  });
  const filteredSportItems = useFilterSports({
    allSportItems,
    filters: {
      searchTitle: value ?? undefined,
    },
  });

  const options = useMemo(() => filteredSportItems.map((v) => makeOption(v)), [filteredSportItems]);

  return <AutoCompleteControlled<Sport> {...other} options={options} value={value} isLoading={isLoading} />;
});

function makeOption(Sport: Sport): AutoCompleteOption<Sport> {
  const { id, name } = Sport;
  return {
    label: name,
    value: name,
    key: id.toString(),
    data: Sport,
  };
}
