import { useCallback, useMemo } from 'react';
import { Player } from '@sportscardinvestor/schemas';
import { useQueryClient } from 'react-query';
import uniqBy from 'lodash/uniqBy';
import {
  useAuthenticatedMMAPIInfiniteQuery,
  useAuthenticatedMMAPIQuery,
  useAuthenticatedMMAPIQueries,
  MmApiInfiniteQueryOptions,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export type { Player } from '@sportscardinvestor/schemas';
export type UsePlayersListInput = MmApiInput['private']['players']['list'] & { useAdminApi?: boolean };
export type UsePlayersListOutput = MmApiOutput['private']['players']['list'];

export const usePlayersListKeyPrefix = 'private.players.list';
export type UsePlayersListQueryKey = [typeof usePlayersListKeyPrefix, UsePlayersListInput];
function makeUsePlayersListQueryKey(input: UsePlayersListInput): UsePlayersListQueryKey {
  return [usePlayersListKeyPrefix, input];
}

export function usePlayersListPaginated(
  input: UsePlayersListInput,
  options?: MmApiQueryOptions<UsePlayersListOutput, UsePlayersListQueryKey>
) {
  const queryKey = makeUsePlayersListQueryKey(input);
  const { useAdminApi, ...commonInput } = input;
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    async () => mmApiClient[useAdminApi ? 'admin' : 'private'].players.list.query(commonInput),
    options
  );

  return result;
}

export function usePlayersListInfinite(
  input: Omit<UsePlayersListInput, 'offset'>,
  options?: MmApiInfiniteQueryOptions<UsePlayersListOutput, UsePlayersListQueryKey>
) {
  const queryKey: UsePlayersListQueryKey = [usePlayersListKeyPrefix, input];
  const { useAdminApi, ...commonInput } = input;
  const result = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    async ({ pageParam }: { pageParam?: number }) =>
      mmApiClient[useAdminApi ? 'admin' : 'private'].players.list.query({
        ...commonInput,
        offset: pageParam ?? 0,
      }),
    {
      ...(options ?? {}),
      getNextPageParam: (_lastPage, pages): number | null => {
        const firstPage = pages[0];
        if (!firstPage || !firstPage.totalCount) {
          return null;
        }
        const totalFetched = pages.reduce((acc, page) => acc + page.items.length, 0);
        if (firstPage.totalCount <= totalFetched) {
          return null;
        }
        return pages.length * (input.limit ?? firstPage.items.length);
      },
    }
  );
  const pages = result.data?.pages;
  const items = useMemo<Player[]>(() => uniqBy(pages?.map(({ items }) => items).flat() ?? [], 'id'), [pages]);
  const totalCount = result.data?.pages?.[0]?.totalCount ?? null;

  const { hasNextPage, fetchNextPage } = result;
  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    items,
    totalCount,
    fetchNextPageIfAvailable,
    fetchNextPage,
    hasNextPage,
  };
}

interface UsePlayersByIdsInput {
  playerIds: string[];
  useAdminApi: boolean;
}

export function usePlayersByIds(
  { playerIds, useAdminApi }: UsePlayersByIdsInput,
  options?: MmApiQueryOptions<UsePlayersListOutput, UsePlayersListQueryKey>
) {
  const queryResults = useAuthenticatedMMAPIQueries(
    playerIds.map((playerId) => {
      const input = makePlayersListInputById({
        playerId,
        useAdminApi,
      });
      return {
        queryFn: () => mmApiClient[useAdminApi ? 'admin' : 'private'].players.list.query(input),
        queryKey: makeUsePlayersListQueryKey(input),
        options,
      };
    })
  );
  const result = queryResults.reduce<{ items: Player[]; isLoading: boolean }>(
    (acc, { data, isLoading }) => {
      const option = data?.items?.[0];
      if (option) {
        acc.items.push(option);
      }
      acc.isLoading = acc.isLoading || isLoading;
      return acc;
    },
    { items: [], isLoading: false }
  );

  const queryClient = useQueryClient();

  const setPlayerItem = useStableFunctionIdentity((item: Player): void => {
    const queryKey = makeUsePlayersListQueryKey(
      makePlayersListInputById({
        playerId: String(item.id),
        useAdminApi,
      })
    );
    queryClient.setQueryData<UsePlayersListOutput>(queryKey, {
      items: [item],
      totalCount: 1,
    });
  });

  return {
    ...result,
    setPlayerItem,
  };
}

interface UsePlayerByIdInput {
  playerId: string;
  useAdminApi?: boolean;
}

function makePlayersListInputById({ playerId, useAdminApi }: UsePlayerByIdInput): UsePlayersListInput {
  return {
    filters: {
      playerIds: [playerId],
    },
    useAdminApi,
    limit: 1,
  };
}

export function usePlayerById(
  { playerId, useAdminApi }: UsePlayerByIdInput,
  options?: MmApiQueryOptions<UsePlayersListOutput, UsePlayersListQueryKey>
) {
  const input = makePlayersListInputById({
    playerId,
    useAdminApi,
  });
  const { data, ...queryResult } = useAuthenticatedMMAPIQuery(
    makeUsePlayersListQueryKey(input),
    async () => mmApiClient[useAdminApi ? 'admin' : 'private'].players.list.query(input),
    options
  );

  const player = data?.items?.[0] ?? null;

  return {
    ...queryResult,
    player,
  };
}
