import { useMemo } from 'react';
import {
  faCircleDollar,
  faStar,
  faSquarePlus,
  faBell,
  faChartColumn,
  faArrowRightToLine,
} from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid, faBell as faBellSolid } from '@fortawesome/pro-solid-svg-icons';
import { ebayAffiliateDisclosure } from '../../../constants';
import { IconButtonSize } from '../../buttons/IconButton/IconButton';
import ExtraActionsRow, { ExtraActionsRowProps, ExtraAction } from 'sci-ui-components/ExtraActionsRow/ExtraActionsRow';
import { ExtraAction as OverflowAction } from 'sci-ui-components/ExtraActionsDropdown/ExtraActionsDropdown';
import { BreakpointValues } from '@/sci-ui-components/types/breakpoints';

export type CollectibleActionsRowProps = Omit<ExtraActionsRowProps, 'actions'> & {
  forSaleUrl?: string | null;
  onForSale?: () => void;
  onChart?: () => void;
  onAddToFavorites?: () => void;
  onRemoveFromFavorites?: () => void;
  isFavorite?: boolean;
  hasAlert?: boolean;
  onAddToCollection?: () => void;
  onAddPriceAlert?: () => void;
  onShowDetails?: () => void;
  overflowActions?: OverflowAction[];
  isCustom?: boolean;
  extraActions?: ExtraAction[];
  size?: IconButtonSize;
  groupOnBreakpoints?: BreakpointValues;
};

export default function CollectibleActionsRow({
  onChart,
  forSaleUrl,
  onForSale,
  onAddToFavorites,
  onRemoveFromFavorites,
  isFavorite,
  onAddToCollection,
  onAddPriceAlert,
  hasAlert,
  onShowDetails,
  overflowActions,
  isCustom,
  extraActions,
  size = 'medium',
  groupOnBreakpoints,
  ...other
}: CollectibleActionsRowProps) {
  const actionOrder = [
    'editPurchaseDetails',
    'editCustomCollectionItem',
    'onMarkAsSold',
    'deleteCollectionItem',
    'convertGrade',
    'onChart',
    'onForSale',
  ];

  const actions = useMemo(() => {
    const items: ExtraAction[] = [];
    if (!isCustom && onChart) {
      items.push({
        id: 'onChart',
        label: 'Chart this Collectible',
        onSelect: onChart,
        faIcon: faChartColumn,
      });
    }
    if (forSaleUrl) {
      items.push({
        id: 'onForSale',
        label: `For Sale \n(${ebayAffiliateDisclosure})`,
        onSelect: onForSale,
        externalLink: forSaleUrl,
        faIcon: faCircleDollar,
      });
    }
    if (!isCustom && onAddToFavorites && !isFavorite) {
      items.push({
        id: 'onAddToFavorites',
        label: 'Add To Favorites',
        onSelect: onAddToFavorites,
        faIcon: faStar,
        highlighted: false,
      });
    }
    if (!isCustom && onRemoveFromFavorites && isFavorite) {
      items.push({
        id: 'onRemoveFromFavorites',
        label: 'Remove From Favorites',
        onSelect: onRemoveFromFavorites,
        faIcon: faStarSolid,
        highlighted: true,
      });
    }
    if (onAddToCollection) {
      items.push({
        id: 'onAddToCollection',
        label: 'Add To Collection',
        onSelect: onAddToCollection,
        faIcon: faSquarePlus,
      });
    }
    if (!isCustom && onAddPriceAlert) {
      items.push({
        id: 'onAddPriceAlert',
        label: 'Add Price Alert',
        onSelect: onAddPriceAlert,
        faIcon: faBell,
        faHighlightedIcon: faBellSolid,
        highlighted: hasAlert,
      });
    }
    if (onShowDetails) {
      items.push({
        id: 'onShowDetails',
        label: 'View Details',
        onSelect: onShowDetails,
        faIcon: faArrowRightToLine,
      });
    }

    if (extraActions?.length) {
      return items.concat(extraActions);
    }

    return items;
  }, [
    onChart,
    forSaleUrl,
    onForSale,
    onAddToFavorites,
    onRemoveFromFavorites,
    isFavorite,
    onAddToCollection,
    onAddPriceAlert,
    hasAlert,
    onShowDetails,
    isCustom,
    extraActions,
  ]);

  const sortActions = (actions: ExtraAction[], order: string[]) => {
    const orderedActions: ExtraAction[] = [];
    const actionMap = new Map(actions.map((action) => [action.id, action]));

    order.forEach((actionId) => {
      const action = actionMap.get(actionId);
      if (action !== undefined) {
        orderedActions.push(action);
        actionMap.delete(actionId);
      }
    });

    actionMap.forEach((action) => orderedActions.push(action));

    return orderedActions;
  };

  const sortedActions = sortActions(actions, actionOrder);

  return (
    <ExtraActionsRow
      color={'grey'}
      size={size}
      {...other}
      groupOnBreakpoints={groupOnBreakpoints}
      actions={sortedActions}
      overflowActions={overflowActions}
    />
  );
}
