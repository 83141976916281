import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import NextLink from 'next/link';

import { Tooltip } from 'antd';
import IconButton, { IconButtonSize, IconButtonColor } from '../buttons/IconButton/IconButton';
import classes from './ExtraActionsRow.module.scss';
import { useBreakpoint } from 'sci-ui-components/hooks/useBreakpoint';
import { Breakpoint } from 'sci-ui-components/types/breakpoints';
import ExtraActionsDropdown from 'sci-ui-components/ExtraActionsDropdown/ExtraActionsDropdown';

const defaultGroupOnBreakpoints = {
  xs: 0,
  sm: 0,
  md: 0,
  ml: 0,
  lg: 3,
  xl: 6,
};

export interface ExtraActionsRowProps<TValue extends string = string> {
  className?: string;
  linkClassname?: string;
  actions: ExtraAction<TValue>[];
  size?: IconButtonSize;
  groupOnBreakpoints?: Partial<Record<Breakpoint, number>>;
  dense?: boolean;
  color?: IconButtonColor;
  overflowActions?: ExtraAction<TValue>[];
}

export interface ExtraAction<TValue extends string = string> {
  id: TValue;
  label: string;
  faIcon: IconDefinition;
  faHighlightedIcon?: IconDefinition;
  onSelect?: (value: TValue) => void;
  externalLink?: string;
  internalLink?: string;
  disabled?: boolean;
  highlighted?: boolean;
  danger?: boolean;
}

export default function ExtraActionsRow({
  className,
  linkClassname,
  actions,
  size = 'small',
  groupOnBreakpoints,
  dense = false,
  color = 'default',
  overflowActions = [],
}: ExtraActionsRowProps) {
  const breakpoint = useBreakpoint(true);
  const effectiveBreakpoint = breakpoint ?? 'md';

  const totalActionIcons = actions.length + (overflowActions.length > 0 ? 1 : 0);
  const groupNumber =
    (groupOnBreakpoints ? groupOnBreakpoints[effectiveBreakpoint] : defaultGroupOnBreakpoints[effectiveBreakpoint]) ??
    totalActionIcons;
  const shouldGroup = groupNumber + 1 < totalActionIcons;
  const spreadItems = shouldGroup ? actions.slice(0, groupNumber) : actions;
  const overflowMenuItems = [...actions.slice(spreadItems.length), ...overflowActions];

  return (
    <div className={clsx(classes.root, { [classes.dense]: dense }, className)}>
      {spreadItems.map(
        ({
          faIcon,
          id,
          label,
          onSelect,
          highlighted,
          faHighlightedIcon,
          externalLink,
          internalLink,
          disabled,
          danger,
        }) => {
          const linkProps =
            externalLink || internalLink
              ? {
                  href: externalLink ?? internalLink,
                  target: externalLink ? '_blank' : undefined,
                  rel: externalLink ? 'noreferrer' : undefined,
                }
              : {};
          const button = (
            <Tooltip key={id} placement="top" title={label} overlayInnerStyle={{ borderRadius: '4px' }}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onSelect?.(id);
                }}
                title={''}
                faIcon={faIcon}
                faHighlightedIcon={faHighlightedIcon}
                highlighted={highlighted}
                size={size}
                color={danger ? 'danger' : color}
                disabled={disabled}
                className={linkClassname}
                {...linkProps}
              />
            </Tooltip>
          );
          if (internalLink) {
            return (
              <NextLink href={internalLink} key={id} legacyBehavior>
                {button}
              </NextLink>
            );
          }
          return button;
        }
      )}
      {!!overflowMenuItems.length && <ExtraActionsDropdown items={overflowMenuItems} size={size} color={color} />}
    </div>
  );
}
