import { makeBooleanParam } from '../../../hooks/useQueryString';
import { filterKeys } from '../constants';
import { StatsMovementsFilters } from '../types';

export default function getQsFromFilters(filters: StatsMovementsFilters | null) {
  if (!filters) {
    return {};
  }
  return {
    [filterKeys.gradeIds]: filters.gradeIds,
    [filterKeys.setNames]: filters.setNames,
    [filterKeys.variationNames]: filters.variationNames,
    [filterKeys.setYears]: filters.setYears,
    [filterKeys.gradedCardsOnly]: makeBooleanParam(filters.gradedCardsOnly),
    [filterKeys.maxAvgSale]: filters.maxAvgSale,
    [filterKeys.maxSaleCount]: filters.maxSaleCount,
    [filterKeys.minAvgSale]: filters.minAvgSale,
    [filterKeys.minSaleCount]: filters.minSaleCount,
    [filterKeys.playerIds]: filters.playerIds,
    [filterKeys.isRookieOnly]: makeBooleanParam(filters.isRookieOnly),
    [filterKeys.boxTypeIds]: filters.boxTypeIds,
    [filterKeys.sportIds]: filters.sportIds,
  };
}
