import { useMemo } from 'react';
import QueryString from 'qs';
import {
  getBooleanParam,
  getNumericArrayParam,
  getNumericParam,
  getStringArrayParam,
  getStringParam,
  makeBooleanParam,
} from '../../utils/queryString';
import useQueryString from '../../hooks/useQueryString';
import { RatioType, RatiosFilters, ratiosTypes } from './types';
import { getItem, setItem } from '@/utils/localStorage';
import filterObjectKeys, { skipUndefinedCondition } from '@/utils/filterObjectKeys';

const storageKeyPrefix = 'sci_ratios_filters';

export const ratioQueryKeys: Record<keyof RatiosFilters, string> = {
  gradeIds: 'grade_ids',
  setYears: 'years',
  setIds: 'card_sets',
  variationIds: 'card_set_vars',
  variationNames: 'variationNames',
  maxAvgSale: 'max_avg',
  minAvgSale: 'min_avg',
  playerIds: 'players',
  isRookieOnly: 'rc',
  includeStats: 'stats',
  timespan: 'timespan',
  gradedCardsOnly: 'gradedCardsOnly',
  ratioType: 'rft',
};

const defaultState: RatiosFilters = {
  ratioType: 'grade',
  timespan: 'last14',
};

function getRatiosFiltersFromQs(query: QueryString.ParsedQs): RatiosFilters {
  const ratioType = getStringParam(query, ratioQueryKeys.ratioType, defaultState.ratioType, (v) =>
    ratiosTypes.includes(v)
  );
  const gradeIds = getNumericArrayParam(query, ratioQueryKeys.gradeIds, defaultState.gradeIds ?? undefined);
  const playerIds = getNumericArrayParam(query, ratioQueryKeys.playerIds, defaultState.playerIds ?? undefined);
  const setYears = getNumericArrayParam(query, ratioQueryKeys.setYears, defaultState.setYears ?? undefined);
  const setIds = getNumericArrayParam(query, ratioQueryKeys.setIds, defaultState.setIds ?? undefined);
  const variationIds = getNumericArrayParam(query, ratioQueryKeys.variationIds, defaultState.variationIds ?? undefined);
  const variationNames = getStringArrayParam(
    query,
    ratioQueryKeys.variationNames,
    defaultState.variationNames ?? undefined
  );
  const maxAvgSale = getNumericParam(query, ratioQueryKeys.maxAvgSale, defaultState.maxAvgSale ?? undefined);
  const minAvgSale = getNumericParam(query, ratioQueryKeys.minAvgSale, defaultState.minAvgSale ?? undefined);
  const timespan = getStringParam(query, ratioQueryKeys.timespan, defaultState.timespan);
  const isRookieOnly = getBooleanParam(query, ratioQueryKeys.isRookieOnly, defaultState.isRookieOnly);
  const gradedCardsOnly = getBooleanParam(query, ratioQueryKeys.gradedCardsOnly, false);

  return {
    gradeIds,
    playerIds,
    setIds,
    setYears,
    variationIds,
    variationNames,
    maxAvgSale,
    minAvgSale,
    isRookieOnly,
    gradedCardsOnly,
    timespan,
    ratioType,
  };
}

export const useRatioFilters = () => {
  const { query, setQueryParams } = useQueryString();
  const filters = useMemo(() => getRatiosFiltersFromQs(query), [query]);
  const setRatioFilters = (filters: Partial<Omit<RatiosFilters, 'ratioType'>>, clearOtherFilters = false) => {
    const newQuery = {
      [ratioQueryKeys.timespan]: filters.timespan,
      [ratioQueryKeys.gradeIds]: filters.gradeIds ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.setYears]: filters.setYears ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.setIds]: filters.setIds ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.variationIds]: filters.variationIds ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.variationNames]: filters.variationNames ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.maxAvgSale]: filters.maxAvgSale ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.playerIds]: filters.playerIds ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.minAvgSale]: filters.minAvgSale ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.timespan]: filters.timespan ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.isRookieOnly]: makeBooleanParam(filters.isRookieOnly) ?? (clearOtherFilters ? null : undefined),
      [ratioQueryKeys.gradedCardsOnly]:
        makeBooleanParam(filters.gradedCardsOnly) ?? (clearOtherFilters ? null : undefined),
    };
    setQueryParams(filterObjectKeys(newQuery, skipUndefinedCondition(newQuery)));
  };
  const resetFilters = () =>
    setRatioFilters(
      {
        timespan: filters.timespan,
      },
      true
    );

  const switchRatiosType = (ratioType: RatioType) => {
    setItem<RatiosFilters>(`${storageKeyPrefix}_${filters.ratioType}`, filters, 'session'); // NOTE: save current filters so we can restore them if user switches back
    setQueryParams({
      [ratioQueryKeys.ratioType]: ratioType,
    });
    const restoredFilters = getItem<RatiosFilters>(`${storageKeyPrefix}_${ratioType}`, true, 'session');
    setRatioFilters(
      restoredFilters ?? {
        timespan: filters.timespan,
      },
      true
    );
  };

  return {
    filters,
    ratiosType: filters.ratioType,
    setRatioFilters,
    resetFilters,
    switchRatiosType,
  };
};
