import { useCallback, useMemo } from 'react';
import { SalePlatform, KnownSellerName } from '@sportscardinvestor/schemas';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import {
  AdvancedChartDataFilters,
  AdvancedSaleTypeFilters,
  AdvancedSellerFilters,
} from '../../sci-ui-components/types/chartData';
import { trackEvent } from '../analytics/trackEvent';

const storageKey = 'sci_adv_filters';

type AdvancedFiltersByType = Partial<Record<CollectibleType, AdvancedChartDataFilters>>;
const defaultAdvancedChartFilters: AdvancedChartDataFilters = {
  seller: {
    excludeNoSellerFeedback: false,
  },
};

export default function useAdvancedChartFilters({ collectibleType }: { collectibleType: CollectibleType }) {
  const [values, setValues] = useLocalStorageValue<AdvancedFiltersByType>(storageKey, true);
  const advancedFilters = useMemo(() => {
    const storedValues = values ? values[collectibleType] : null;
    if (!storedValues) {
      return defaultAdvancedChartFilters;
    }
    if (storedValues.excludeSellers?.length) {
      // NOTE: these are no longer used, but might have been previously saved by users
      storedValues.excludeSellers = storedValues.excludeSellers.filter((v) => !(v in ['pwcc_auctions', 'pwcc_vault']));
    }

    return storedValues;
  }, [values, collectibleType]);

  const setFilters = useCallback(
    (newFilters: AdvancedChartDataFilters) => {
      const newValues = {
        ...(values ?? {}),
        [collectibleType]: newFilters,
      };
      setValues(newValues);
      trackEvent({
        eventName: 'CHARTS_ADVANCED_FILTERS_CHANGED',
        collectibleType,
        filters: newValues,
      });
    },
    [collectibleType, values, setValues]
  );

  const toggleSaleType = useCallback(
    (type: keyof AdvancedSaleTypeFilters) => {
      const currentSaleType = advancedFilters.saleType ?? {};
      return setFilters({
        ...advancedFilters,
        saleType: {
          ...currentSaleType,
          [type]: !currentSaleType[type],
        },
      });
    },
    [advancedFilters, setFilters]
  );
  const toggleSeller = useCallback(
    (type: keyof AdvancedSellerFilters) => {
      const current = advancedFilters.seller ?? {};
      return setFilters({
        ...advancedFilters,
        seller: {
          ...current,
          [type]: !current[type],
        },
      });
    },
    [advancedFilters, setFilters]
  );
  const toggleExcludeSeller = useCallback(
    (seller: KnownSellerName) => {
      const current = advancedFilters.excludeSellers ?? [];
      return setFilters({
        ...advancedFilters,
        excludeSellers: current.includes(seller) ? current.filter((c) => c !== seller) : [...current, seller],
      });
    },
    [advancedFilters, setFilters]
  );
  const toggleExcludePlatform = useCallback(
    (platform: SalePlatform) => {
      const current = advancedFilters.excludePlatforms ?? [];
      return setFilters({
        ...advancedFilters,
        excludePlatforms: current.includes(platform) ? current.filter((p) => p !== platform) : [...current, platform],
      });
    },
    [advancedFilters, setFilters]
  );

  return {
    advancedFilters,
    toggleSaleType,
    toggleSeller,
    toggleExcludeSeller,
    toggleExcludePlatform,
  };
}
