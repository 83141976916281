import { ReactNode } from 'react';
import { Filters } from '../filters/types';

export const ratiosTypes = ['grade', 'variation', 'player'] as const;
export type RatioType = (typeof ratiosTypes)[number];
export type RatioTimespan = 'all' | 'last7' | 'last14' | 'last30';

export type RatiosFilters = Pick<
  Filters,
  | 'gradeIds'
  | 'setYears'
  | 'playerIds'
  | 'setIds'
  | 'variationIds'
  | 'variationNames'
  | 'isRookieOnly'
  | 'gradedCardsOnly'
> & {
  maxAvgSale?: number | null;
  minAvgSale?: number | null;
  timespan: RatioTimespan;
  ratioType: RatioType;
  includeStats?: boolean;
};
export const applicableFilters = [
  'setYears',
  'playerIds',
  'gradeIds',
  'variationNames',
  'isRookieOnly',
  'setIds',
  'variationIds',
  'gradedCardsOnly',
] as const satisfies (keyof RatiosFilters)[];

export type RatioComponentSearchParams = {
  filters: RatiosFilters;
};

export interface FiltersComponentProps {
  searching: boolean;
  filters: RatiosFilters;
  onFiltersChange: (filters: Partial<RatiosFilters>, closeFilters?: boolean) => void;
  onReset: () => void;
}

export type RatioTableColumns = { left: () => ReactNode; right: () => ReactNode };
