import { useMemo } from 'react';
import { Collectible, CustomCollectible } from '@sportscardinvestor/schemas';
import { chartCollectible } from '../charts/ChartsDialog/index';
import { chartPopulationCount } from '../charts/PopulationCountChartDialog';
import { priceAlertDialog } from '../alerts/PriceAlertDialog';
import { PriceAlertDialogInfoProps } from '../alerts/PriceAlertDialog/types';
import { useFavoriteIds } from '../favorites/useFavoriteIds';
import useFavoriteCollectibleMutations from '../favorites/useFavoriteCollectibleMutations';
import { addFavorite } from '../favorites/AddOrEditFavoriteDialog/index';
import useUser from '../user/useUser';
import { DateRangeName } from '../../sci-ui-components/utils/date';
import { useAlertIds } from '../alerts/useAlertIds';
import { showSignupIncentiveModal } from '../signupIncentive';
import { trackEvent } from '../analytics/trackEvent';
import { useMakeAddToCollectionInitialValues } from '../collection/AddCollectionItemDialog/useMakeAddToCollectionInitialValues';
import { CollectibleType } from './constants';
import { useCollectibleTypeValue } from './CollectibleTypeValueContext';
import { showCollectibleDetails } from './CollectibleDetailsFlyout/store';
import { useCollectible, useCollectibleCacheHelper } from './useCollectible';
import { parseCustomCardQueryString } from 'services/sciApi/collections/utils/constructCustomCardQueryString';
import constructEbayQuery from 'services/affiliates/utils/constructEbayQuery';
import { addCollectionItem } from 'features/collection/AddCollectionItemDialog/index';
import { showError } from 'services/toaster';
import { marketplaceHelpers } from 'services/marketplaceHelpers';

export function useCommonCollectiblesActions({
  collectibleType: collectibleTypeFromParam,
}: {
  collectibleType?: CollectibleType;
} = {}) {
  const collectibleTypeFromContext = useCollectibleTypeValue();
  const collectibleType = collectibleTypeFromParam ?? collectibleTypeFromContext;
  const { checkIfFavorite, getFavoriteIdByEntityId, isUserAtFavoritesLimit } = useFavoriteIds({
    entityType: collectibleType,
  });
  const { checkIfHasAlert } = useAlertIds({ collectibleType });
  const { removeFavoriteCollectibleWithConfirmation } = useFavoriteCollectibleMutations();
  const { data: user } = useUser();
  const isPublic = !user;
  const { makeFromCollectible } = useMakeAddToCollectionInitialValues();
  const { setCollectible } = useCollectibleCacheHelper();

  return useMemo(
    () => ({
      chartCollectible: (collectibleIds: number[], dateRange?: DateRangeName) => {
        if (isPublic) {
          showSignupIncentiveModal();
          return Promise.resolve(false);
        }
        trackEvent({
          eventName: 'CHARTS_PREVIEW_DIALOG_VIEWED',
          collectibleIds,
          collectibleType,
        });

        return chartCollectible({ collectibleIds, collectibleType, dateRange });
      },
      chartPopulationCount: (collectibleId: number) => {
        if (isPublic) {
          showSignupIncentiveModal();
          return Promise.resolve(false);
        }
        trackEvent({
          eventName: 'POPULATION_CHART_DIALOG_VIEWED',
          collectibleId,
          collectibleType,
        });
        return chartPopulationCount({ collectibleId, collectibleType });
      },
      addCollectionItem,
      addCollectibleToCollection: ({ collectible }: { collectible: Collectible | CustomCollectible }) =>
        addCollectionItem(
          makeFromCollectible({
            collectible,
          })
        ),
      checkIfHasAlert: (collectibleId: number | undefined | null) =>
        collectibleId ? checkIfHasAlert(collectibleId) : false,
      addPriceAlert: (collectibleId: number, initialValues?: PriceAlertDialogInfoProps['initialValues']) => {
        if (isPublic) {
          showSignupIncentiveModal();
          return;
        }
        priceAlertDialog({ collectibleType, collectibleId, initialValues, mode: 'Add' });
        trackEvent({
          eventName: 'ALERTS_ADD_DIALOG_OPENED',
          collectibleId,
          collectibleType,
        });
      },
      addFavorite: (collectibleId: number) => {
        if (isPublic) {
          showSignupIncentiveModal();
          return;
        }
        if (isUserAtFavoritesLimit) {
          showError({
            description: (
              <div>
                You&apos;ve reached your plan&apos;s limit for favorites.{' '}
                <a
                  href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Remove a favorite or upgrade your plan to add more favorites.
                </a>
              </div>
            ),
          });
          return;
        }

        addFavorite({ collectibleId, collectibleType });
        trackEvent({
          eventName: 'FAVORITES_ADD_DIALOG_OPENED',
          collectibleId,
          collectibleType,
        });
      },
      removeFavorite: (collectibleId: number) => {
        if (isPublic) {
          showSignupIncentiveModal();
          return;
        }
        trackEvent({
          eventName: 'FAVORITES_REMOVE_DIALOG_OPENED',
          collectibleId,
          collectibleType,
        });
        const id = getFavoriteIdByEntityId(collectibleId);
        if (id) {
          return removeFavoriteCollectibleWithConfirmation({ id, collectibleType });
        } else {
          // NOTE: should not really happen, but just in case
          showError({
            message: 'Selected collectible has already been removed from favorites',
          });
        }
      },
      checkIfFavorite,
      showCollectibleDetails: ({
        collectible,
        ...params
      }: {
        collectibleId: number;
        collectibleType: CollectibleType;
        isCustom: boolean;
        /**
         * Optional, but when provided, speeds up fly out data loading
         */
        collectible?: Collectible | null;
      }) => {
        if (collectible) {
          setCollectible(collectible);
        }
        showCollectibleDetails(params);
        trackEvent({
          eventName: 'COLLECTIBLES_DETAILS_VIEWED',
          collectibleId: params.collectibleId,
          collectibleType: params.collectibleType,
        });
      },
      getForSaleUrl: (query: string | null | undefined, isCustom = false) => {
        if (!query) {
          return null;
        }
        if (isCustom) {
          const parsedCustomQuery = parseCustomCardQueryString(query);
          const ebayQuery = constructEbayQuery({
            termsToInclude: parsedCustomQuery.queryOtherTerms,
            termsToExclude: parsedCustomQuery.queryExcludeTerms,
            sportsCardNumber: parsedCustomQuery.queryCardNumber?.toString(),
            sportsCardGradeQueries: parsedCustomQuery.queryGrade ? [parsedCustomQuery.queryGrade] : undefined,
            sportQueries: parsedCustomQuery.querySport ? [parsedCustomQuery.querySport] : undefined,
            setYearQueries: parsedCustomQuery.queryYear ? [parsedCustomQuery.queryYear] : undefined,
            setVariationQueries: parsedCustomQuery.querySetVariation
              ? [parsedCustomQuery.querySetVariation]
              : undefined,
            setQueries: parsedCustomQuery.querySet ? [parsedCustomQuery.querySet] : undefined,
            sealedWaxBoxTypeQuery: parsedCustomQuery.queryBoxTypeName,
            playerNameQueries: parsedCustomQuery.queryPlayerName ? [parsedCustomQuery.queryPlayerName] : undefined,
            excludeSealedWaxTerms: parsedCustomQuery.queryExcludeSealed,
            excludeLots: parsedCustomQuery.queryExcludeLots,
          });
          return marketplaceHelpers.makeSearchUrl({ query: ebayQuery, marketplace: 'ebay_jobs', activeOnly: true });
        }
        return marketplaceHelpers.makeSearchUrl({ query, marketplace: 'ebay_jobs', activeOnly: true });
      },
      removeFavoriteCollectibleWithConfirmation,
      trackForSaleClick: (collectibleId: number) => {
        trackEvent({
          eventName: 'SALES_MARKETPLACE_LINK_CLICKED',
          marketplace: 'ebay_jobs', // NOTE: the only supported at the moment
          collectibleId,
          collectibleType,
        });
      },
    }),
    [
      collectibleType,
      getFavoriteIdByEntityId,
      isPublic,
      checkIfFavorite,
      checkIfHasAlert,
      isUserAtFavoritesLimit,
      makeFromCollectible,
      removeFavoriteCollectibleWithConfirmation,
      setCollectible,
    ]
  );
}

export interface CommonCollectibleActions {
  showCollectibleDetails: () => void;
  chartCollectible: () => Promise<boolean>;
  chartPopulationCount: () => void;
  addToCollection: () => void;
  addPriceAlert: () => void;
  addFavorite: () => void;
  removeFavorite: () => void;
  isFavorite: boolean;
  forSaleUrl: string | null;
  trackForSaleClick: () => void;
  hasAlert: boolean;
}

export function useCommonCollectibleActions({
  collectibleId: collectibleIdParam,
  customCollectible,
  query,
  collectibleType: collectibleTypeFromParam,
}: {
  collectibleId: number | null | undefined;
  customCollectible?: CustomCollectible | null;
  query?: string | null;
  collectibleType?: CollectibleType;
}) {
  const collectibleId = collectibleIdParam ?? customCollectible?.id;
  const collectibleTypeFromContext = useCollectibleTypeValue();
  const collectibleType = collectibleTypeFromParam ?? collectibleTypeFromContext;
  const actions = useCommonCollectiblesActions({ collectibleType });
  const { data: collectible } = useCollectible(
    { collectibleId: collectibleId!, collectibleType },
    { enabled: !query && !customCollectible && !!collectibleId }
  );
  const forSaleQuery = query ?? collectible?.query;
  const hasAlert = actions.checkIfHasAlert(collectible?.id);

  return useMemo<CommonCollectibleActions>(
    () => ({
      showCollectibleDetails: () =>
        collectibleId &&
        actions.showCollectibleDetails({ collectibleId, collectibleType, isCustom: !!customCollectible }),
      chartCollectible: () => {
        if (customCollectible) {
          return Promise.resolve(false); // NOTE: chartung custom collectibles is not supported
        }
        if (collectibleId) {
          return actions.chartCollectible([collectibleId]);
        }
        return Promise.resolve(false);
      },
      chartPopulationCount: () => {
        if (collectibleId && !customCollectible) {
          return actions.chartPopulationCount(collectibleId);
        }
      },
      addToCollection: () => {
        if (customCollectible) {
          return actions.addCollectibleToCollection({ collectible: customCollectible });
        } else if (collectible) {
          return actions.addCollectibleToCollection({ collectible });
        }
      },
      addPriceAlert: () => collectibleId && actions.addPriceAlert(collectibleId),
      addFavorite: () => collectibleId && actions.addFavorite(collectibleId),
      removeFavorite: () => collectibleId && actions.removeFavorite(collectibleId),
      isFavorite: collectibleId ? actions.checkIfFavorite(collectibleId) : false,
      forSaleUrl: actions.getForSaleUrl(forSaleQuery, !!customCollectible),
      trackForSaleClick: () => collectibleId && actions.trackForSaleClick(collectibleId),
      hasAlert,
    }),
    [actions, collectibleId, forSaleQuery, hasAlert, collectibleType, customCollectible, collectible]
  );
}
