import { memo, useMemo } from 'react';
import { makeSetDisplayName } from '@sportscardinvestor/collectible-helpers';
import { CollectiblesSet, CollectibleType } from '@sportscardinvestor/schemas';
import { useSetsListInfinite } from './useSetsList';
import useDebouncedValue from '@/sci-ui-components/hooks/useDebouncedValue';
import {
  AutoCompleteControlled,
  AutoCompleteOption,
  AutoCompleteProps,
} from 'sci-ui-components/forms/AutoComplete/AutoComplete';

export type SetsAutoCompleteProps = Omit<AutoCompleteProps<CollectiblesSet>, 'options' | 'makeOption'> & {
  cardSetYears?: number[];
  sportIds?: number[];
  noDebounce?: boolean;
  useAdminApi?: boolean;
  collectibleType: CollectibleType;
  limit?: number;
};
export const SetsAutoComplete = memo(function CardSetsAutoComplete({
  value,
  cardSetYears,
  sportIds,
  noDebounce,
  useAdminApi,
  collectibleType,
  limit = 50,
  ...other
}: SetsAutoCompleteProps) {
  const [debouncedValue, isDebouncing] = useDebouncedValue(value, noDebounce ? 0 : undefined);
  const { items, fetchNextPageIfAvailable, hasNextPage, isLoading, isFetchingNextPage } = useSetsListInfinite({
    filters: {
      searchTitle: debouncedValue ?? '',
      setYears: cardSetYears?.map((v) => String(v)),
      sportIds: sportIds?.map((v) => String(v)),
    },
    collectibleType,
    useAdminApi,
    sort: [
      {
        sortBy: 'setName',
        sortDirection: 'asc',
      },
    ],
    limit,
  });

  const options = useMemo(() => items.map(makeOption), [items]);

  return (
    <AutoCompleteControlled<CollectiblesSet>
      {...other}
      options={options}
      value={value}
      hasMore={hasNextPage}
      onEndReached={fetchNextPageIfAvailable}
      isLoading={isLoading || isDebouncing || isFetchingNextPage}
      endReachedThreshold={3}
    />
  );
});

function makeOption(set: CollectiblesSet): AutoCompleteOption<CollectiblesSet> {
  const { id, name, year, sport } = set;
  const displayText = makeSetDisplayName({
    setName: name,
    setYear: year ?? null,
    sportName: sport.name,
  });
  return {
    label: displayText,
    value: displayText,
    key: id.toString(),
    data: set,
  };
}
