import clsx from 'clsx';

import classes from './CateogryChip.module.scss';

export interface CategoryChipProps {
  category: string | undefined | null;
  className?: string;
}

/**
 * A visual indicator of the user category of a collectable
 */
export default function CategoryChip({ category = null, className }: CategoryChipProps) {
  if (!category) {
    return null;
  }

  return (
    <span className={clsx(classes.chip, className)}>
      <span className={clsx(classes.categoryText)} title={category}>
        {category}
      </span>
    </span>
  );
}
