import { BoxType } from '@sportscardinvestor/schemas';

/**
 * These are not valid box type values - they do not exist in DB and cannot be assigned to entities.
 * They are aggregations used only for their queries
 */
export const anySealedWaxBoxTypeOptions: BoxType[] = [
  {
    id: -1,
    name: 'Any Boxes',
    query: 'box -case',
  },
  {
    id: -2,
    name: 'Any Cases',
    query: 'case -hit',
  },
  {
    id: -3,
    name: 'Any Packs',
    query: 'pack -(box,boxes,case,cases)',
  },
];
