import { useMemo } from 'react';
import { Sport } from '@sportscardinvestor/schemas';
import SimpleSelect, { SimpleSelectProps, Option } from '../../sci-ui-components/forms/SimpleSelect/SimpleSelect';
import { useSportsListPaginated } from './useSportsList';

export type SportsSingleSelectProps = Omit<
  SimpleSelectProps<number>,
  'options' | 'searchText' | 'onSearchTextChange'
> & {
  className?: string;
  useAdminApi?: boolean;
  allowOther?: boolean;
};

export function SportsSingleSelect({
  value,
  onChange,
  useAdminApi = false,
  allowOther = false,
  ...other
}: SportsSingleSelectProps) {
  const { items: allSportItems = [], isLoading } = useSportsListPaginated({
    useAdminApi,
    allowOther,
  });

  const options = useMemo(() => allSportItems.map((v) => makeOption(v)), [allSportItems]);

  return <SimpleSelect {...other} value={value} options={options} onChange={onChange} isLoading={isLoading} />;
}

function makeOption({ id, name }: Sport): Option<number> {
  return {
    label: name,
    value: id,
  };
}
